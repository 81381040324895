import React, { useState } from 'react';

import * as globalConstants from '@common/Constants';
import { mockLearningPathDetails } from '@common/tests/mockedData/EventsMockedData';
import { useGlobalContext } from '@hooks/Context/GlobalContext';
import * as constants from '@pages/EventDetails/Constants';
import Information from '@pages/LearningPathDetails/Tabs/Information';
import Overview from '@pages/LearningPathDetails/Tabs/Overview';

import LearningPathDetailsDesktop from './Components/LearningPathDetailsDesktop';
import LearningPathDetailsMobile from './Components/LearningPathDetailsMobile';

const LearningPathDetails = () => {
  const [learningPath] = useState(mockLearningPathDetails.course);
  const [contextData] = useGlobalContext();
  const [activeItem, setActiveItem] = useState(constants.tabOptions.overview);

  const handleActiveComponent = (component) => {
    switch (component) {
      case constants.tabOptions.information:
        return <Information learningPath={learningPath} config={contextData} />;
      default:
        return <Overview learningPath={learningPath} config={contextData} />;
    }
  };

  const handleTabClick = (e, data) => setActiveItem(data.name);

  return (
    <div data-testid='learningPathDetails'>
      <LearningPathDetailsDesktop
        learningPath={learningPath}
        contextData={contextData}
        isLoading={false}
        handleActiveComponent={handleActiveComponent}
        handleTabClick={handleTabClick}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />

      {/* mobile layout - content*/}
      {contextData.size && contextData.size.device === globalConstants.deviceSize.mobile && (
        <LearningPathDetailsMobile
          learningPath={learningPath}
          contextData={contextData}
          isLoading={false}
          handleActiveComponent={handleActiveComponent}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      )}
    </div>
  );
};

export default LearningPathDetails;
