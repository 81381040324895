import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import * as constants from './Constants';
import style from '../EventDetails/style/DetailsMenuItems.module.scss';

const DetailsMenuItems = (props) => {
  const { activeItem, handleItemClick, showIcon, config } = props;

  return (
    <>
      <Menu.Item
        data-testid={'detailsMenuItemsInfo'}
        name={constants.tabOptions.information}
        active={activeItem === constants.tabOptions.information}
        onClick={handleItemClick}
        disabled={!config?.AllSessionsTabs.information}
      >
        {Localize.get('EventDetails.information')}
        {showIcon && <Icon name='info' className={style.itemIcon} />}
      </Menu.Item>
      <Menu.Item
        data-testid={'detailsMenuItemsAgenda'}
        name={constants.tabOptions.agenda}
        active={activeItem === constants.tabOptions.agenda}
        onClick={handleItemClick}
        disabled={!config?.AllSessionsTabs.agenda}
      >
        {Localize.get('EventDetails.agenda')}
        {showIcon && <Icon name='calendar outline' className={style.itemIcon} />}
      </Menu.Item>
      <Menu.Item
        data-testid={'detailsMenuItemsParticipants'}
        name={constants.tabOptions.participants}
        active={activeItem === constants.tabOptions.participants}
        onClick={handleItemClick}
        disabled={!config?.AllSessionsTabs.participants}
      >
        {Localize.get('TrainerAreaDetails.participants')}
        {showIcon && <Icon name='user' className={style.itemIcon} />}
      </Menu.Item>
      <Menu.Item
        data-testid={'detailsMenuItemsHotel'}
        name={constants.tabOptions.hotel}
        active={activeItem === constants.tabOptions.hotel}
        onClick={handleItemClick}
        disabled={!config?.AllSessionsTabs.hotel}
      >
        {Localize.get('TrainerAreaDetails.hotel')}
        {showIcon && <Icon name='bed' className={style.itemIcon} />}
      </Menu.Item>

      <Menu.Item
        data-testid={'detailsMenuItemsAttachments'}
        name={constants.tabOptions.attachments}
        active={activeItem === constants.tabOptions.attachments}
        onClick={handleItemClick}
        disabled={!config?.AllSessionsTabs.attachments}
      >
        {Localize.get('EventDetails.attachments')}
        {showIcon && <Icon name='attach' className={style.itemIcon} />}
      </Menu.Item>
      <Menu.Item
        data-testid={'detailsMenuItemsNotes'}
        name={constants.tabOptions.notes}
        active={activeItem === constants.tabOptions.notes}
        onClick={handleItemClick}
        disabled={!config?.AllSessionsTabs.notes}
      >
        {Localize.get('EventDetails.notes')}
        {showIcon && <Icon name='clipboard outline' className={style.itemIcon} />}
      </Menu.Item>
    </>
  );
};

export default DetailsMenuItems;
