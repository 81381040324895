import Localize from 'react-intl-universal';

import { format, parseISO, compareAsc, isValid } from 'date-fns';
import { enUS, de } from 'date-fns/locale';
import moment from 'moment/moment';

import * as routerConstants from '@navigation/Constants.js';
import { courseSessionType } from '@pages/CourseDetails/Constants.js';

import * as globalConstants from './Constants.js';

export const getDayName = (date = new Date(), locale = 'en-US') => {
  return date.toLocaleDateString(locale, { weekday: 'short' });
};

export const isAfterToday = (date) => {
  const today = new Date();
  return new Date(date) > today;
};

export const formatLongDateStringToDateObject = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  const dateString = timestamp.substring(0, 10);
  let date = new Date(dateString);
  date.setHours(1, 0);
  return date;
};

// Should be used for dateTime strings which are in this format - YYYY-MM-DDTHH-MM-SS
export const formatLongDateTimeStringToDateTimeObject = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(parseISO(timestamp), 'dd.MM.yyyy HH:mm');
};
// Should be used for dateTime strings which are in this format - YYYY-MM-DD
export const formatDateTimeStringToDateTimeObject = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(parseISO(timestamp), 'dd.MM.yyyy');
};

// Should be used for dateTime strings which are in this format - YYYY-MM-DD
export const formatDateTimeStringToShortMonthDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(parseISO(timestamp), 'MMM d');
};

export const formatDateStringToDateObject = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  const dateString = timestamp.substring(0, 8);
  let date = new Date(
    dateString.substring(0, 4) + '-' + dateString.substring(4, 6) + '-' + dateString.substring(6, 8)
  );
  date.setHours(1, 0);
  return date;
};

export const formatDateStringToDateTimeObject = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  const dateString = timestamp.substring(0, 8);
  let date = new Date(
    dateString.substring(0, 4) + '-' + dateString.substring(4, 6) + '-' + dateString.substring(6, 8)
  );
  date.setHours(timestamp.substring(8, 10));
  date.setMinutes(timestamp.substring(10, 12));
  return date;
};

export const formatDateTimeObjectToTimestamp = (date) => {
  if (!date) {
    return '';
  }
  const yearString = date.getFullYear();
  let month = date.getMonth();
  month += 1;
  let monthStr = month.toString();

  if (month < 10) {
    monthStr = '0' + month.toString();
  }

  const day = date.getDate();
  let dayStr = day.toString();

  if (day < 10) {
    dayStr = '0' + day.toString();
  }

  const hours = date.getHours();
  let hoursStr = hours.toString();

  if (hours < 10) {
    hoursStr = '0' + day.toString();
  }

  const minutes = date.getHours();
  let minutesStr = minutes.toString();

  if (minutes < 10) {
    minutesStr = '0' + minutes.toString();
  }

  return yearString + monthStr + dayStr + hoursStr + minutesStr;
};

export const formatDateRange = (start, end) => {
  if (!start || !end) {
    return '';
  }
  const stFormatted = formatDateDMY(start);

  if (start.getTime() === end.getTime()) {
    return stFormatted;
  }

  const endFormatted = formatDateDMY(end);

  return stFormatted + ' - ' + endFormatted;
};

const getDatesMonthAndDate = (date) => {
  if (!date) {
    return '';
  }
  let stDate = date.getDate();
  if (stDate < 10) {
    stDate = '0' + stDate;
  }
  let stMonth = date.getMonth() + 1;
  if (stMonth < 10) {
    stMonth = '0' + stMonth;
  }

  return {
    date: stDate,
    month: stMonth,
    dayName: getDayName(date, window.navigator.languages[0])
  };
};

export const formatDateDMY = (date) => {
  if (!date) {
    return '';
  }

  const dateMonthAndDate = getDatesMonthAndDate(date);
  const stYear = date.getFullYear();
  return dateMonthAndDate.date + '.' + dateMonthAndDate.month + '.' + stYear;
};

export const formatDateDMYSlashDelimiter = (date) => {
  if (!date) {
    return '';
  }

  const dateMonthAndDate = getDatesMonthAndDate(date);
  const stYear = date.getFullYear();
  return dateMonthAndDate.date + '/' + dateMonthAndDate.month + '/' + stYear;
};

export const momentFormatDateDMYSlashDelimiter = (date) => {
  if (!date) {
    return '';
  }
  return moment(date).format('DD/MM/GGGG');
};

export const formatDateYMDDashDelimiter = (date) => {
  if (!date) {
    return '';
  }

  const dateMonthAndDate = getDatesMonthAndDate(date);
  const stYear = date.getFullYear();
  return stYear + '-' + dateMonthAndDate.month + '-' + dateMonthAndDate.date;
};

export const formatDateTimeStringToDateObject = (dateTimeString) => {
  if (!dateTimeString) {
    return '';
  }

  // If last character is Z then Date function will add 1 hour and time will not be correct
  const getLastChar = dateTimeString.slice(-1);
  return formatDateDMYHM(
    getLastChar === 'Z' ? new Date(dateTimeString.slice(0, -1)) : new Date(dateTimeString)
  );
};

export const formatDateDMYHM = (date) => {
  if (!date) {
    return '';
  }
  const dateMonthAndDate = getDatesMonthAndDate(date);
  const stYear = date.getFullYear();
  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  let hours = date.getHours();
  if (hours < 10) {
    hours = '0' + hours;
  }
  return (
    dateMonthAndDate.date +
    '.' +
    dateMonthAndDate.month +
    '.' +
    stYear +
    '    ' +
    hours +
    ':' +
    minutes
  );
};

export const formatDateDMYHMWithDayName = (date) => {
  if (!date) {
    return '';
  }
  if (!isValid(date)) {
    return Localize.get('AppNavBar.invalidDate');
  }

  let browserLanguage = navigator.language || (navigator.languages && navigator.languages[0]);

  if (!browserLanguage) {
    browserLanguage = 'en-US';
  }

  const locale = browserLanguage.toLowerCase().startsWith('de') ? de : enUS;

  return format(date, 'dd.MM.yyyy E HH:mm', { locale });
};

export const formatDateHHTimeZone = (date) => {
  if (!date) {
    return '';
  }

  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  let hours = date.getHours();

  if (hours < 13) {
    if (hours === 0 && minutes === '00') {
      minutes += ' PM';
    } else {
      minutes += ' AM';
    }
  } else {
    hours -= 12;
    minutes += ' PM';
  }

  let displayHours = hours;
  if (hours < 10) {
    displayHours = '0' + hours;
  }
  return displayHours + ':' + minutes;
};

export const formatDateToTimestamp = (dateObj, isStartDate) => {
  const date = new Date(dateObj);
  const year = date.getFullYear();

  let month = date.getMonth();
  month += 1;
  let monthStr = month.toString();

  if (month < 10) {
    monthStr = '0' + month.toString();
  }

  const day = date.getDate();
  let dayStr = day.toString();

  if (day < 10) {
    dayStr = '0' + day.toString();
  }

  // this method is used to parse start and end date upon search events,
  // if it is start_date set hours and minutes onto '0000' to cover whole day;
  // same goes for end date with value '2359'
  const hoursAndMinutes = isStartDate ? '0000' : '2359';

  return year.toString() + monthStr + dayStr + hoursAndMinutes;
};

export const getAttachmentMimeType = (attachmentExtension) => {
  if (!attachmentExtension) {
    return 'text/plain';
  }

  let mimeGroup = '';
  let mimeType;

  for (const [key, value] of Object.entries(globalConstants.supportedAttachmentTypes)) {
    if (value.includes(attachmentExtension.toLowerCase())) {
      mimeGroup = key;
      break;
    }
  }

  switch (mimeGroup) {
    case 'image':
      mimeType = 'image/png';
      break;
    case 'svg':
      mimeType = 'image/svg+xml';
      break;
    case 'doc':
    case 'docx':
      mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case 'xlsx':
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'pdf':
      mimeType = 'application/pdf';
      break;
    default:
      mimeType = 'text/plain';
      break;
  }
  return mimeType;
};

export const getRouteDisplayName = (route) => {
  let routeDisplayName;
  switch (route) {
    case routerConstants.routes.path.home:
      routeDisplayName = Localize.get('AppNavBar.home');
      break;
    case routerConstants.routes.path.catalog:
      routeDisplayName = Localize.get('AppNavBar.learningContent');
      break;
    case routerConstants.routes.path.events:
      routeDisplayName = Localize.get('AppNavBar.customerLoyaltyEvents');
      break;
    case routerConstants.routes.path.myevents:
      routeDisplayName = Localize.get('AppNavBar.myEvents');
      break;
    case routerConstants.routes.path.generalinfo:
      routeDisplayName = Localize.get('AppNavBar.genInfo');
      break;
    case routerConstants.routes.path.signup:
      routeDisplayName = Localize.get('SignIn.signUp');
      break;
    case routerConstants.routes.path.login:
      routeDisplayName = Localize.get('SignIn.logIn');
      break;
    case routerConstants.routes.path.eventDetails:
      routeDisplayName = Localize.get('EventDetails.eventDetails');
      break;
    case routerConstants.routes.path.register:
      routeDisplayName = Localize.get('App.register');
      break;
    default:
      routeDisplayName = Localize.get('AppNavBar.home');
      break;
  }
  return routeDisplayName;
};

export const setLanguage = () => {
  let appLang = 'EN';
  let browserLang = window.navigator.language;

  if (!browserLang) {
    browserLang = window.navigator.languages[0];
  }

  if (browserLang.includes('de')) {
    appLang = 'DE';
  }

  return appLang;
};

export const capitalizeFirstLetter = (string) =>
  string?.charAt(0)?.toUpperCase() + string?.slice(1);

export const getSessionLocation = (session) => {
  if (session?.type?.key === courseSessionType.classroom) {
    return session?.location && session.location.length
      ? session.location
      : Localize.get('CourseDetails.venueIsMissing');
  } else {
    return Localize.get('CourseDetails.webinar');
  }
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const dateTimeSort = (dateTimeA, dateTimeB) => {
  return compareAsc(parseISO(dateTimeA), parseISO(dateTimeB));
};

export const checkRegistrationStatus = (statusKey) => {
  switch (statusKey) {
    case globalConstants.registrationStatuses.reserved:
      return globalConstants.bookingStatus.waitingList;
    case globalConstants.registrationStatuses.registered:
      return globalConstants.bookingStatus.successful;
    default:
      return globalConstants.bookingStatus.decline;
  }
};

export const checkIfAlreadyAssigned = (sessionParticipants) => {
  return sessionParticipants
    .map((item) => {
      switch (item.status.key) {
        case globalConstants.registrationStatuses.waitingCancelled:
        case globalConstants.registrationStatuses.cancelled:
          return false;
        case globalConstants.registrationStatuses.completed:
        case globalConstants.registrationStatuses.reserved:
        case globalConstants.registrationStatuses.registered:
        case globalConstants.registrationStatuses.notCompleted:
          return true;
        default:
          return false;
      }
    })
    .some((item) => item === true);
};

export const checkIfUserIsAlreadyRegistered = (courseSessions) => {
  const registeredSession = courseSessions?.find(
    (session) =>
      session?.sessionParticipantDtos !== null &&
      checkIfAlreadyAssigned(session.sessionParticipantDtos)
  );
  return registeredSession !== undefined
    ? { userAlreadyRegistered: true, sessions: [registeredSession] }
    : { userAlreadyRegistered: false, sessions: courseSessions };
};

export const openPrivacyPolicyTab = () => {
  window.open(routerConstants.routes.name.privacyPolicy, '_blank');
};

export const openLegalNoticeTab = () => {
  window.open(routerConstants.routes.name.legalNotice, '_blank');
};

export const openTermsAndConditionsTab = () => {
  window.open(routerConstants.routes.name.termsAndConditions, '_blank');
};

export const getIltSessionPrice = (session) => {
  return `${
    session?.iltPrices?.find(
      (price) => price?.type?.key === globalConstants.iltPricesTypeKey.standardPrice
    )?.price ?? 0
  } ${
    session?.iltPrices?.find(
      (price) => price?.type?.key === globalConstants.iltPricesTypeKey.standardPrice
    )?.currency?.value ?? globalConstants.iltPricesCurrency.eur
  }`;
};
