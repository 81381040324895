import React, { Fragment } from 'react';
import Localize from 'react-intl-universal';
import { useHistory } from 'react-router-dom';

import get from 'lodash/get';
import { Grid, Button } from 'semantic-ui-react';

import { courseType } from '@common/Constants';
import { registrationStatuses } from '@common/Constants';
import { checkRegistrationStatus } from '@common/Util';
import * as routerConstants from '@navigation/Constants';

import BookingHeader from './BookingHeader';
import BookingInformation from './BookingInformation';
import { bookingType } from '../../Constants';
import style from '../style/RegistrationCourse.module.scss';

const ConfirmationPage = ({
  courseTypeName,
  setShowConfirmationPage,
  config,
  participantDetails,
  setParticipantDetails,
  setRegistrationActive,
  courseId,
  registrationResponse
}) => {
  let history = useHistory();

  const getMoreDetailsLabel = () => {
    return (
      <div>
        {Localize.get('RegistrationForm.findMoreAttachments')}{' '}
        <span
          className={style.registrationLink}
          onClick={() => {
            history.push({
              pathname: routerConstants.routes.path.myarea,
              state: {
                from: Localize.get('CourseDetails.courseDetails') + '/' + courseId,
                path: routerConstants.routes.path.myarea
              }
            });
          }}
          onKeyDown={() => {}}
          onKeyUp={() => {}}
          role='button'
          tabIndex={0}
        >
          {Localize.get('RegistrationForm.myArea')}
        </span>{' '}
        {Localize.get('RegistrationForm.or')}{' '}
        <span
          className={style.registrationLink}
          onClick={() => {
            history.push({
              pathname: routerConstants.routes.path.teamArea,
              state: {
                from: Localize.get('CourseDetails.courseDetails') + '/' + courseId,
                path: routerConstants.routes.path.teamArea
              }
            });
          }}
          onKeyDown={() => {}}
          onKeyUp={() => {}}
          role='button'
          tabIndex={0}
        >
          {Localize.get('RegistrationForm.teamArea')}
        </span>
        {'.'}
      </div>
    );
  };

  const handleOk = () => {
    setShowConfirmationPage(false);
    setRegistrationActive(false);
  };

  const checkBookingStatus = (iltSessionId, participantId) => {
    const registeredSession = registrationResponse
      ?.find((response) => response?.userId === participantId)
      ?.userSessions?.find((session) => session.sessionId === iltSessionId);
    return checkRegistrationStatus(
      get(registeredSession, 'participantResult.key', registrationStatuses.cancelled)
    );
  };

  return (
    <div className={style.registrationContainer} data-testid={'ConfirmationPage'}>
      <Grid
        className={`tablet computer only ${style.menuCenter}`}
        centered
        style={{ paddingTop: config?.configuration?.CourseDetailsInfoContent?.paddingTop }}
      >
        <Grid.Row>
          <Grid.Column>
            <h3>{`${Localize.get('RegistrationForm.statusOverview')}`}</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <b>{Localize.get('RegistrationForm.participant')}</b>
          </Grid.Column>
          <Grid.Column width={13}>
            {courseTypeName === Localize.get(`App.${courseType.course}`) && (
              <BookingHeader
                config={config}
                bookingInformationType={bookingType.confirmationPage}
              />
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={3}>{participantDetails.name}</Grid.Column>
          <Grid.Column width={13}>
            <Grid>
              {participantDetails.iltModulesSessions.length &&
              courseTypeName === Localize.get(`App.${courseType.course}`) ? (
                participantDetails.iltModulesSessions.map((iltModule, index) => (
                  <BookingInformation
                    key={index}
                    participantDetails={participantDetails}
                    setParticipantDetails={setParticipantDetails}
                    iltModule={iltModule}
                    iltModuleIndex={index}
                    config={config}
                    bookingInformationType={bookingType.confirmationPage}
                    bookingInformationStatus={checkBookingStatus(
                      parseInt(get(iltModule, 'iltSessions[0].id', 0)),
                      get(participantDetails, 'participantId', ''),
                      ''
                    )}
                  />
                ))
              ) : (
                <Fragment />
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
        {participantDetails?.participants &&
          participantDetails.participants.map((participant, participantIndex) => (
            <Grid.Row
              key={participantIndex}
              style={{ paddingTop: config.configuration.Registration.paddingTop }}
            >
              <Grid.Column width={3}>{participant.name}</Grid.Column>
              <Grid.Column width={13}>
                <Grid>
                  {participant.iltModulesSessions.length &&
                  courseTypeName === Localize.get(`App.${courseType.course}`) ? (
                    participant.iltModulesSessions.map((iltModule, index) => (
                      <BookingInformation
                        key={index}
                        participantDetails={participant}
                        setParticipantDetails={setParticipantDetails}
                        iltModule={iltModule}
                        iltModuleIndex={index}
                        config={config}
                        participantIndex={participantIndex}
                        bookingInformationType={bookingType.confirmationPage}
                        bookingInformationStatus={checkBookingStatus(
                          parseInt(get(iltModule, 'iltSessions[0].id', 0)),
                          get(participant, 'participantInfo.employeeId', '')
                        )}
                      />
                    ))
                  ) : (
                    <Fragment />
                  )}
                </Grid>
              </Grid.Column>
            </Grid.Row>
          ))}

        <Grid.Row style={{ paddingTop: config.configuration.Registration.paddingTop }}>
          <Grid.Column textAlign={'center'}>
            <b>{getMoreDetailsLabel()}</b>
            <b>{Localize.get('RegistrationForm.makeChangeOrCancel')}</b>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingBottom: config.configuration.Registration.paddingBottom }}>
          <Button
            content={Localize.get('App.ok')}
            className={style.registerButton}
            onClick={() => handleOk()}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ConfirmationPage;
