import React from 'react';
import Localize from 'react-intl-universal';

// Semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import SimpleTable from '@pages/AllSession/SimpleTable';

import { iltSessionsTableColumns } from '../config/iltSessionsTableColumns';
import useMyAreaIltSessionTableLogic from '../hooks/useMyAreaIltSessionTableLogic';

const IltSessionsTable = (props) => {
  const { iltSessionsToShow, activeTabIndex, config } = props;

  const {
    paginationModel,
    setPaginationModel,
    filteredIltSessions,
    iltSessionsCount,
    singleSelectValues,
    searchCompRef,
    onSearchFieldChange,
    isLoading
  } = useMyAreaIltSessionTableLogic(iltSessionsToShow, activeTabIndex, config);

  return (
    <>
      <Grid.Column width={14} data-testid={'iltSessionsTable'}>
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        <Input
          fluid
          icon='search'
          iconPosition='left'
          placeholder={Localize.get('Catalog.searchPlaceholder')}
          onChange={onSearchFieldChange}
          ref={searchCompRef}
        />

        <SimpleTable
          data={filteredIltSessions}
          columns={[
            ...iltSessionsTableColumns.map((column) => {
              const localizedHeaderName = Localize.get(column.headerName);
              if (column.type === 'singleSelect') {
                let valueOptions = [];
                switch (column.field) {
                  case 'instructors':
                    valueOptions = singleSelectValues.allUniqueInstructors;
                    break;
                  case 'locations':
                    valueOptions = singleSelectValues.allUniqueLocations;
                    break;
                  case 'cities':
                    valueOptions = singleSelectValues.allUniqueCities;
                    break;
                  case 'courseName':
                    valueOptions = singleSelectValues.allUniqueCourseNames;
                    break;
                  case 'moduleName':
                    valueOptions = singleSelectValues.allUniqueModuleNames;
                    break;
                  default:
                    break;
                }
                return {
                  ...column,
                  headerName: localizedHeaderName,
                  valueOptions
                };
              } else {
                return {
                  ...column,
                  headerName: localizedHeaderName
                };
              }
            })
          ]}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          isLoading={isLoading}
          elementsCount={iltSessionsCount}
          config={config}
          showSelect={false}
          showButton={false}
          allowCheckBox={false}
          paginationMode={'client'}
        />
      </Grid.Column>
    </>
  );
};

export default IltSessionsTable;
