import React from 'react';
import Localize from 'react-intl-universal';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import { courseDescriptionType } from '@common/Constants';
import { formatDateDMY } from '@common/Util';

import IltSessionsDescription from './IltSessionsDescription';
import CourseDescription from '../../Components/CourseDescription';
import style from '../style/RegistrationCourse.module.scss';

const IltSessions = ({
  iltModulesSessions,
  selectedIltSessionForModule,
  setSelectedIltSessionForModule,
  iltSessionsForModule,
  currentModule,
  config
}) => {
  const handleIltChange = (_, { value }) => {
    setSelectedIltSessionForModule({
      ...selectedIltSessionForModule,
      [currentModule]: { ...selectedIltSessionForModule[currentModule], sessionId: value }
    });
  };

  const moduleDetails = (currentModule) => {
    return iltModulesSessions.filter((val) => val.ID === currentModule);
  };

  const checkForIltSession = (currentModule) => {
    return iltModulesSessions.reduce((acc, val) => {
      if (val.ID === currentModule && !val.iltSessions.length) {
        acc = false;
      }

      return acc;
    }, true);
  };

  const moduleDescription = (selectedModule) => {
    return selectedModule[0] ? (
      <CourseDescription
        description={selectedModule[0].DESCRIPTION}
        type={courseDescriptionType.module}
      />
    ) : (
      <div />
    );
  };

  const moduleName = (selectedModule) => {
    return selectedModule[0] ? <h3>{selectedModule[0].NAME}</h3> : <div />;
  };

  const getAgenda = (iltSessionsForModule, selectedSession) => {
    if (!iltSessionsForModule || !iltSessionsForModule.length) {
      return null;
    }
    const selectedIltSession = iltSessionsForModule.find(
      (iltSession) => iltSession.id === selectedSession
    );
    return selectedIltSession?.sessionDayDtos ? (
      selectedIltSession.sessionDayDtos.map((sessionDay, index) => {
        return (
          <Grid.Row key={index}>
            <Grid.Column width={16}>{formatDateDMY(new Date(sessionDay.dayDate))}</Grid.Column>
            {sessionDay.agenda.map((agenda, index) => (
              <Grid.Column
                key={index}
                width={16}
              >{`${agenda.startTime} - ${agenda.endTime} ${agenda.name}`}</Grid.Column>
            ))}
          </Grid.Row>
        );
      })
    ) : (
      <></>
    );
  };

  return (
    <Grid
      data-testid={'IltSessions'}
      className={`tablet computer only ${style.menuCenter}`}
      centered
      style={{ background: config?.configuration?.CourseDetailsContent.background }}
    >
      <Grid.Row columns={2}>
        <Grid.Column width={10}>
          {checkForIltSession(currentModule)
            ? moduleName(moduleDetails(currentModule))
            : Localize.get('CourseDetails.noDesc')}
        </Grid.Column>
        <Grid.Column width={6}>
          <h3>{Localize.get('CourseDetails.agenda')}</h3>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2} divided>
        <Grid.Column width={10}>
          {checkForIltSession(currentModule)
            ? moduleDescription(moduleDetails(currentModule))
            : Localize.get('CourseDetails.noDesc')}
          <Grid
            style={{
              paddingTop: config?.configuration?.CourseDetailsInfoContent?.paddingTop,
              paddingLeft: config?.configuration?.CourseDetailsInfoContent?.paddingLeft
            }}
            column={16}
          >
            <IltSessionsDescription
              iltSessionsForModule={iltSessionsForModule(currentModule)}
              selectedSession={
                selectedIltSessionForModule[currentModule]
                  ? selectedIltSessionForModule[currentModule]['sessionId']
                  : ''
              }
              handleIltChange={handleIltChange}
              config={config}
            />
          </Grid>
        </Grid.Column>

        <Grid.Column width={6}>
          {selectedIltSessionForModule[currentModule] &&
            selectedIltSessionForModule[currentModule]['sessionId'] && (
              <Grid>
                {getAgenda(
                  iltSessionsForModule(currentModule),
                  selectedIltSessionForModule[currentModule]
                    ? selectedIltSessionForModule[currentModule]['sessionId']
                    : ''
                )}
              </Grid>
            )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default IltSessions;
