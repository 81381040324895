import { getService, postService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const createManagerEmployeesWithCoursesService = (email) => {
  const path =
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.managerEmployeesWithCourses}` +
    `${email}`;

  return getService(path);
};

export const createRegistrationForCoursesService = (courseId, participantDetails) => {
  const path = `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.registerToCourse}${courseId}`;

  return postService(path, { courseId, participantDetails });
};
