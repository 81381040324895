import styled from 'styled-components';

export const Footer = styled.footer`
  background-color: ${(props) => props.configuration.footer.background} !important;
  display: flex;
  min-height: 2.5rem;
  justify-content: space-between; /* Align content to the start and end */
  align-items: center; /* Vertically center the content */
  padding: 0 1rem; /* Add padding to the whole footer */
  color: white; /* Set the text color to white */
`;

export const FooterContent = styled.div`
  flex: 1; /* Takes the available space on the left side */
`;

export const FooterButtons = styled.div`
  display: flex;
  gap: 1rem; /* Adjust the spacing between buttons */
`;
