import Localize from 'react-intl-universal';

import * as constants from './Constants';

export const getMobileMenuItems = (activeItem, handleItemClickLocally, config) => {
  if (!activeItem || !handleItemClickLocally) {
    return null;
  }
  return [
    {
      name: constants.tabOptions.information,
      active: activeItem === constants.tabOptions.information,
      onClick: handleItemClickLocally,
      key: Localize.get('EventDetails.information'),
      text: Localize.get('EventDetails.information'),
      value: Localize.get('EventDetails.information'),
      icon: { name: 'info' },
      selected: null,
      disabled: !config?.AllSessionsTabs?.information
    },
    {
      name: constants.tabOptions.agenda,
      active: activeItem === constants.tabOptions.agenda,
      onClick: handleItemClickLocally,
      key: Localize.get('EventDetails.agenda'),
      text: Localize.get('EventDetails.agenda'),
      value: Localize.get('EventDetails.agenda'),
      icon: { name: 'calendar outline' },
      selected: null,
      disabled: !config?.AllSessionsTabs?.agenda
    },
    {
      name: constants.tabOptions.participants,
      active: activeItem === constants.tabOptions.participants,
      onClick: handleItemClickLocally,
      key: Localize.get('TrainerAreaDetails.participants'),
      text: Localize.get('TrainerAreaDetails.participants'),
      value: Localize.get('TrainerAreaDetails.participants'),
      icon: { name: 'user' },
      selected: null,
      disabled: !config?.AllSessionsTabs?.participants
    },
    {
      name: constants.tabOptions.hotel,
      active: activeItem === constants.tabOptions.hotel,
      onClick: handleItemClickLocally,
      key: Localize.get('TrainerAreaDetails.hotel'),
      text: Localize.get('TrainerAreaDetails.hotel'),
      value: Localize.get('TrainerAreaDetails.hotel'),
      icon: { name: 'bed' },
      selected: null,
      disabled: !config?.AllSessionsTabs?.hotel
    },
    {
      name: constants.tabOptions.attachments,
      active: activeItem === constants.tabOptions.attachments,
      onClick: handleItemClickLocally,
      key: Localize.get('EventDetails.attachments'),
      text: Localize.get('EventDetails.attachments'),
      value: Localize.get('EventDetails.attachments'),
      icon: { name: 'attach' },
      selected: null,
      disabled: !config?.AllSessionsTabs?.attachments
    },
    {
      name: constants.tabOptions.notes,
      active: activeItem === constants.tabOptions.notes,
      onClick: handleItemClickLocally,
      key: Localize.get('EventDetails.notes'),
      text: Localize.get('EventDetails.notes'),
      value: Localize.get('EventDetails.notes'),
      icon: { name: 'clipboard outline' },
      selected: null,
      disabled: !config?.AllSessionsTabs?.notes
    }
  ];
};
