import { useState, useRef, useEffect } from 'react';
import Localize from 'react-intl-universal';

//lodash
import debounce from 'lodash/debounce';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

import Logger from '@common/Logger';
import useFetching from '@hooks/Fetching/Fetching';
import { createMyAreaIltSessionsService } from '@pages/MyArea/MainHelper';
import tokenUtil from '@services/tokenUtil';

const initialSingleSelectStateValues = {
  allUniqueInstructors: [],
  allUniqueLocations: [],
  allUniqueCities: [],
  allUniqueCourseNames: [],
  allUniqueModuleNames: []
};

const useMyAreaIltSessionTableLogic = (iltSessionsToShow, activeTabIndex, config) => {
  const appUser = tokenUtil.getUser();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [allIltSessions, setAllIltSessions] = useState([]);
  const [filteredIltSessions, setFilteredIltSessions] = useState([]);
  const [iltSessionsCount, setIltSessionsCount] = useState(0);
  const [singleSelectValues, setSingleSelectValues] = useState(initialSingleSelectStateValues);
  const [searchTerm, setSearchTerm] = useState();

  const searchCompRef = useRef(null);

  const [iltSessionsData, isLoading, , fetchIltSessions] = useFetching(
    createMyAreaIltSessionsService.bind(
      null,
      // 'tobias.rothley@contrimo.com', // TO DO: Remove for logged in user
      appUser.email ? appUser.email : 'tobias.rothley@contrimo.com',
      iltSessionsToShow
    ),
    true
  );

  // eslint-disable-next-line no-unused-vars
  const onSearchFieldChange = debounce((_, data) => {
    setSearchTerm(data?.value);
  }, 700); // Set the desired delay in milliseconds

  const extractUniqueNames = (sessions, key, propName) =>
    uniq(flatMap(sessions, (session) => (session[key]?.length ? map(session[key], propName) : [])));

  const formatIltSessionDataForTable = (iltSessions) => {
    return iltSessions.map((session) => {
      // TO DO: check how it works when Instructor model is finished on TEM BE
      const instructors =
        session?.instructorsDto?.map((instructor) => instructor.name).join(', ') || [];
      const cities = session?.venues?.map((venue) => venue?.city).join(', ') || [];
      const locations = session?.venues?.map((venue) => venue?.name).join(', ') || [];

      return {
        id: session?.id,
        startDateTime: session?.startDateTime,
        endDateTime: session?.endDateTime,
        instructors,
        name: session?.name,
        courseName: session?.course?.name,
        moduleName: session?.module?.name,
        sessionType: Localize.get(`MyAreaIltSessionsTab.${session?.type?.key.toLowerCase()}`),
        status: session?.currentUserILTSessionParticipantStatus
          ? Localize.get(
              `MyAreaIltSessionsTab.${
                config?.configuration?.MyAreaIltSessionsTab?.iltSessionStatus[
                  session.currentUserILTSessionParticipantStatus
                ]
              }`
            )
          : '',
        seminarNumber: session?.seminarNumber,
        locations,
        cities
      };
    });
  };

  useEffect(() => {
    if (iltSessionsData?.data?.sessions) {
      const { sessions, sessionsCount } = iltSessionsData.data;
      const formattedIltSessions = formatIltSessionDataForTable(sessions);
      setAllIltSessions(formattedIltSessions);
      setIltSessionsCount(sessionsCount);
      // TO DO: check how it works when Instructor model is finished on TEM BE
      setSingleSelectValues({
        ...singleSelectValues,
        allUniqueInstructors: extractUniqueNames(sessions, 'instructorsDto', 'name'),
        allUniqueLocations: extractUniqueNames(sessions, 'venues', 'name'),
        allUniqueCities: extractUniqueNames(sessions, 'venues', 'city'),
        allUniqueCourseNames: uniq(map(sessions, (session) => session?.course?.name)),
        allUniqueModuleNames: uniq(map(sessions, (session) => session?.module?.name))
      });
      searchCompRef.current.inputRef.current.value === ''
        ? setFilteredIltSessions(formattedIltSessions)
        : setSearchTerm(searchCompRef.current.inputRef.current.value);
    }
  }, [iltSessionsData]);

  useEffect(() => {
    try {
      fetchIltSessions();
    } catch (e) {
      Logger.error(e);
    }
  }, [activeTabIndex]);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredIltSessions(allIltSessions);
    } else {
      // search by ilt session name, module name and course name
      const filteredItems = allIltSessions.filter(
        ({ name, moduleName, courseName }) =>
          name.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          moduleName.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          courseName.toLowerCase().includes(searchTerm.trim().toLowerCase())
      );
      setFilteredIltSessions(filteredItems);
      setIltSessionsCount(filteredItems?.length);
    }
  }, [searchTerm]);

  return {
    paginationModel,
    setPaginationModel,
    filteredIltSessions,
    iltSessionsCount,
    singleSelectValues,
    searchCompRef,
    onSearchFieldChange,
    isLoading
  };
};

export default useMyAreaIltSessionTableLogic;
