import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

import DragDropFiles from '@components/DragAndDropFile/DragAndDropFile';

import styles from './style/Attachments.module.scss';

const UploadModal = (props) => {
  const { open, setOpen, config } = props;
  const handleChange = () => {};

  return (
    <Modal
      data-testid={'uploadAttachmentsModal'}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      className={styles.attachModal}
      dimmer='inverted'
    >
      <Modal.Header className={styles.centeredHeader}>
        {Localize.get('TrainerAreaAttachmentTable.uploadNew')}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor='first'>{Localize.get('TrainerAreaAttachmentTable.fileName')}</label>
            <input
              id='first'
              placeholder={Localize.get('TrainerAreaAttachmentTable.displayName')}
              name='fileName'
              onChange={handleChange}
            />
          </Form.Field>
          <label htmlFor='visibility' className={styles.visibilityLabel}>
            {Localize.get('TrainerAreaAttachmentTable.visibility')}
          </label>
          <Form.Field id='visibility' className={styles.firstCheck}>
            <Checkbox label={Localize.get('TrainerAreaAttachmentTable.administration')} />
          </Form.Field>
          <Form.Field className={styles.check}>
            <Checkbox label={Localize.get('TrainerAreaAttachmentTable.trainers')} />
          </Form.Field>
          <Form.Field className={styles.check}>
            <Checkbox label={Localize.get('TrainerAreaAttachmentTable.participants')} />
          </Form.Field>
          <Form.Field className={styles.check}>
            <Checkbox label={Localize.get('TrainerAreaAttachmentTable.locationResp')} />
          </Form.Field>
        </Form>
        <DragDropFiles config={config} />
      </Modal.Content>
      <Modal.Actions className={styles.modalAction}>
        <Button color='red' onClick={() => setOpen(false)} className={styles.modalButton}>
          {Localize.get('App.cancel').toUpperCase()}
        </Button>
        <Button color='blue' onClick={() => setOpen(false)} className={styles.modalButton}>
          {Localize.get('App.next').toUpperCase()}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UploadModal;
