import React, { useState, useEffect } from 'react';
import Localize from 'react-intl-universal';

import stylesConfig from '@config/styles.config.json';
import GlobalContextProvider from '@hooks/Context/GlobalContext.js';
import AppRouter from '@navigation/Router';
import './App.css';

const App = () => {
  const language = navigator.language.toLowerCase().includes('de') ? 'de' : 'en';
  const customConfig = stylesConfig.hoermann;

  const locales = {
    en: require('./locales/en-us.json'),
    de: require('./locales/de-de.json')
  };

  const loadLocales = () => {
    Localize.init({
      currentLocale: language,
      locales
    }).then(() => {
      setLocalizeInitDone(true);
    });
  };

  const [localizeInitDone, setLocalizeInitDone] = useState(false);
  useEffect(() => {
    loadLocales();
  });

  // needed to add new constant, because test is not executing
  return (
    <div className='App' style={{ background: customConfig?.global?.background }}>
      {localizeInitDone && (
        <GlobalContextProvider>
          <AppRouter configuration={customConfig} />
        </GlobalContextProvider>
      )}
    </div>
  );
};

export default App;
