import React from 'react';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import { notesTypes } from '@pages/AllSession/Constants';
import SessionDetailsNote from '@pages/AllSession/SessionDetailsNote';
import style from '@pages/EventDetails/Tabs/style/Information.module.scss';

const Notes = (props) => {
  const { notesData, config, isLoading } = props;
  return (
    <>
      <Grid.Column
        data-testid={'allSessionNotesTab'}
        width={14}
        style={{ background: config?.configuration?.AllSessionDetailsContent.background }}
      >
        <Grid.Row centered className={`${style.mobileContainer}`}>
          <Grid.Column computer={16} mobile={16}>
            <SessionDetailsNote
              notesData={notesData}
              isLoading={isLoading}
              noteType={notesTypes.trainer}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid.Column>
    </>
  );
};

export default Notes;
