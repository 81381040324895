import React from 'react';
import Localize from 'react-intl-universal';

import { StyledSpan, StyledTypography } from '@components/StyledComponents/TableHeading.styled';
import Box from '@mui/material/Box';

const AttachmentAvailableField = ({ params }) => {
  return (
    <Box data-testid={'attachmentAvailableField'}>
      <StyledTypography>
        {params?.visibleToAdministrators && (
          <>
            <StyledSpan>
              {Localize.get('TrainerAreaAttachmentTable.visibleToAdministrators')}
            </StyledSpan>
            <span>/</span>
          </>
        )}
        {params?.visibleToTrainers && (
          <>
            <StyledSpan>{Localize.get('TrainerAreaAttachmentTable.visibleToTrainers')}</StyledSpan>
            <span>/</span>
          </>
        )}
        {params?.visibleToParticipants && (
          <>
            <StyledSpan>
              {Localize.get('TrainerAreaAttachmentTable.visibleToParticipants')}
            </StyledSpan>
            <span>/</span>
          </>
        )}
        {params?.visibleToLocationResponsible && (
          <>
            <StyledSpan>
              {Localize.get('TrainerAreaAttachmentTable.visibleToLocationResponsible')}
            </StyledSpan>
          </>
        )}
      </StyledTypography>
    </Box>
  );
};

export default AttachmentAvailableField;
