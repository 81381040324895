import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';

import Logger from '@common/Logger';
import {
  MyAreaColumnContentContainer,
  MyAreaContentContainer,
  MyAreaGridColumnContainer,
  MyAreaGridContainer,
  MyAreaMobileMenu,
  MyAreaRowContentContainer,
  StyledTabMenu
} from '@components/StyledComponents/MyArea.styled';
import useFetching from '@hooks/Fetching/Fetching';
import * as constants from '@pages/TeamArea/Constants';
import { createManagerEmployeesWithCoursesService } from '@pages/TeamArea/MainHelper';
import CoursesTab from '@pages/TeamArea/Tabs/SubTabs/CoursesTab';
import TabMenuItems from '@pages/TeamArea/TeamAreaTabItems';

const TrainingsTab = (props) => {
  const { contextData, activeTabIndex } = props;
  const [activeItem, setActiveItem] = React.useState(constants.tabOptions.coursesTab);

  const [employeesWithCourses, setEmployeesWithCourses] = React.useState([]);
  const [employeesWithCoursesCount, setEmployeesWithCoursesCount] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [sortingOption, setSortingOption] = React.useState(null);
  const [filterOption, setFilterOption] = React.useState({
    field: null,
    value: null,
    operator: null
  });
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPagesEmp, setTotalPagesEmp] = React.useState(0);

  const searchCompRefEmp = React.useRef(null);
  const searchMobRefEmp = React.useRef(null);

  const [employeesCoursesData, isLoading, setIsLoading, fetchEmployeesCourses] = useFetching(
    createManagerEmployeesWithCoursesService.bind(
      null,
      contextData?.user,
      currentPage,
      contextData,
      searchTerm,
      sortingOption,
      filterOption
    ),
    true
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentPage && contextData?.size && activeTabIndex === 1) {
          setIsLoading(true);
          fetchEmployeesCourses();
          if (searchCompRefEmp?.current?.inputRef?.current) {
            searchCompRefEmp?.current.inputRef.current.scrollIntoView();
          }
          if (searchMobRefEmp?.current?.inputRef?.current) {
            searchMobRefEmp?.current.inputRef.current.scrollIntoView();
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [contextData, currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (employeesCoursesData?.data?.employeesWithCourses) {
          setEmployeesWithCourses(employeesCoursesData.data.employeesWithCourses);
          setEmployeesWithCoursesCount(employeesCoursesData.data.employeesWithCoursesCount);

          setTotalPagesEmp(
            Math.ceil(
              employeesCoursesData.data.employeesWithCoursesCount /
                contextData?.configuration?.PAGINATION.managerEmployeesLimit.grid[
                  contextData.size.device.toLowerCase()
                ]
            )
          );
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [employeesCoursesData, activeTabIndex]);

  const handleActiveComponent = (component) => {
    let setComponent;
    switch (component) {
      case constants.tabOptions.coursesTab:
        setComponent = (
          <CoursesTab
            employeesCoursesData={employeesWithCourses}
            employeesWithCoursesCount={employeesWithCoursesCount}
            setEmployeesWithCoursesCount={setEmployeesWithCoursesCount}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            fetchEmployeesCourses={fetchEmployeesCourses}
            setSearchTerm={setSearchTerm}
            setSortingOption={setSortingOption}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPagesEmp={totalPagesEmp}
            searchCompRef={searchCompRefEmp}
            searchMobRef={searchMobRefEmp}
          />
        );
        break;
      case constants.tabOptions.learningPathsTab:
        setComponent = <></>;
        break;
      default:
        setComponent = null;
        break;
    }
    return setComponent;
  };

  const handleItemClick = (e, data) => {
    setActiveItem(data.name);
  };

  return (
    <MyAreaGridContainer data-test-id={'trainingsTabGridContainer'}>
      <MyAreaGridColumnContainer
        computer={16}
        largeScreen={16}
        widescreen={16}
        tablet={16}
        mobile={16}
      >
        {/* desktop && tablet layout - menu and content*/}
        <MyAreaContentContainer className={'tablet computer only'} centered>
          <MyAreaColumnContentContainer computer={16} largeScreen={14} widescreen={10} tablet={16}>
            <StyledTabMenu pointing secondary widths={4}>
              <TabMenuItems activeItem={activeItem} handleItemClick={handleItemClick} />
            </StyledTabMenu>

            <Grid stackable container centered>
              <MyAreaRowContentContainer>
                {handleActiveComponent(activeItem)}
              </MyAreaRowContentContainer>
            </Grid>
          </MyAreaColumnContentContainer>
        </MyAreaContentContainer>

        {/* mobile layout - content*/}
        <MyAreaGridContainer centered className={'mobile only'}>
          <Grid.Column width={16}>
            <Grid.Row>
              <Dropdown
                placeholder={Localize.get(`TeamArea.${activeItem}`)}
                fluid
                className='ui selection'
              >
                <MyAreaMobileMenu>
                  <TabMenuItems activeItem={activeItem} handleItemClick={handleItemClick} />
                </MyAreaMobileMenu>
              </Dropdown>
            </Grid.Row>
            <Grid.Row>{handleActiveComponent(activeItem)}</Grid.Row>
          </Grid.Column>
        </MyAreaGridContainer>
      </MyAreaGridColumnContainer>
    </MyAreaGridContainer>
  );
};

export default TrainingsTab;
