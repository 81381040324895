import { NavLink } from 'react-router-dom';

import { Icon } from 'semantic-ui-react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';
import styled, { css } from 'styled-components';

import forwardAsProp from './forwardAsProp';

const textOverflow = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const greetingStyle = css`
  ${(props) =>
    props?.configuration &&
    css`
      float: left;
    `}
  ${(props) =>
    !props?.configuration &&
    css`
      float: right;
    `}
  ${(props) =>
    !props?.user &&
    css`
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        margin-right: -1rem !important;
      }
      @media only screen and (max-width: 766px) and (min-width: 280px) {
        padding-right: 0.2rem;
      }
    `}
  ${(props) =>
    props?.pointer &&
    css`
      @media only screen and (max-width: 990px) and (min-width: 280px) {
        cursor: pointer;
      }
    `}
@media only screen and (max-width: 991px) and (min-width: 768px) {
    float: none;
    padding-left: 0.2rem !important;
  }
  @media only screen and (max-width: 990px) and (min-width: 280px) {
    ${textOverflow}
    float: none;
  }
  @media only screen and (max-width: 766px) and (min-width: 280px) {
    max-width: 5rem !important;
  }
  @media only screen and (max-width: 990px) and (min-width: 767px) {
    max-width: 15vw !important;
  }
`;

export const HeaderGrid = styled(Grid)`
  background-color: ${(props) => props.configuration.header.background} !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  color: ${(props) => props.configuration.header.color} !important;
  vertical-align: middle !important;
  @media only screen and (max-width: 767px) {
    margin: 0 !important;
  }
`;

export const HeaderGridMobile = styled(Grid)`
  margin: 0 !important;
  height: 100% !important;
`;

export const HeaderContainerGridRow = styled(Grid.Row)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  max-height: fit-content !important;
  min-height: 100%;
`;

export const HeaderTabletGridRow = styled(Grid.Row)`
  @media only screen and (max-width: 1368px) and (min-width: 767px) {
    padding-top: inherit !important;
  }
`;

export const HeaderContainerGridColumn = styled(Grid.Column)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

export const HeaderGridColumn = styled(Grid.Column)`
  padding-bottom: 0 !important;
  margin-left: 0 !important;
  padding-right: ${(props) => (props.isgreeting && props.user ? 0 : '1rem')} !important;

  @media only screen and (max-width: 1780px) and (min-width: 767px) {
    padding-right: 0 !important;
    padding-left: ${(props) => (props.isgreeting && props.user ? 0 : '1rem')} !important;
  }
`;

export const HeaderTabletNavBarColumn = styled(Grid.Column)`
  padding-bottom: 0 !important;
  padding-left: 0 !important;
`;

export const HeaderTabletUserDropdownColumn = styled(Grid.Column)`
  padding-bottom: 0 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
`;

export const HeaderTabletUserDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${(props) =>
    !props?.user &&
    css`
      @media only screen and (max-width: 990px) and (min-width: 767px) {
        padding-left: 3rem !important;
        justify-content: space-evenly;
      }
    `}
`;

export const IconGreetingColumn = styled(Grid.Column)`
  padding-left: 0 !important;
  margin-left: inherit !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  ${(props) =>
    !props?.user &&
    css`
      @media only screen and (max-width: 2560px) and (min-width: 1023px) {
        justify-content: flex-end !important;
        padding-right: 3rem !important;
      }
    `}
  ${(props) =>
    props?.user &&
    css`
      @media only screen and (max-width: 2560px) and (min-width: 1281px) {
        justify-content: flex-end !important;
        padding-right: 3rem !important;
      }
    `}
`;

export const LogoColumn = styled(HeaderGridColumn)`
  align-self: center;

  @media only screen and (min-width: 991px) {
    padding-right: 0 !important;
  }
`;

export const IconFirstColumn = styled(HeaderGridColumn)`
  width: auto !important;
`;

export const GreetingColumn = styled(HeaderGridColumn)`
  flex: 1 !important;
  padding-left: 0 !important;
`;

export const LogoContainer = styled(Image)`
  margin-left: 2rem;
  @media only screen and (max-width: 1050px) and (min-width: 768px) {
    margin-right: 0 !important;
    margin-left: auto;
  }
`;

export const GreetingDiv = styled.div`
  ${greetingStyle}
`;

export const GreetingDivWithOverflow = styled(GreetingDiv)`
  @media only screen and (max-width: 1920px) and (min-width: 280px) {
    ${textOverflow}
  }
`;

export const GreetingDivWithUser = styled(GreetingDiv)`
  @media only screen and (max-width: 1920px) and (min-width: 280px) {
    ${textOverflow}
  }
  ${(props) =>
    props?.user &&
    css`
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        max-width: none !important;
      }
    `}
`;

export const GreetingDivContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) =>
    props?.configuration &&
    css`
      margin-left: inherit !important;
    `}
  ${(props) =>
    !props?.configuration &&
    css`
      margin-left: auto !important;
    `}
`;

export const UserIcon = styled(Icon)`
  margin-left: auto !important;
  padding-right: 1rem !important;
`;

export const CustomHeader = styled.div`
  width: 100% !important;
  align-self: stretch;
  flex-direction: column;
`;

export const NavBarGridColumn = styled(Grid.Column)`
  padding-left: 2rem !important;
`;

export const MenuContainer = styled(Grid.Column)`
  padding-right: 0 !important;
`;

export const StyledMenu = styled(Menu)`
  &.secondary.inverted.menu a.item {
    color: ${(props) => props?.configuration?.color} !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  ${(props) =>
    props?.configuration?.selected &&
    css`
      &.secondary.inverted.menu .active.item {
        color: ${props.configuration.selected} !important;
        background-color: ${props.configuration.backgroundSelected} !important;
      }
      &.secondary.inverted.menu a.item:focus {
        color: ${props.configuration.selected} !important;
      }
      &.secondary.inverted.menu a.item:hover {
        color: ${(props) => props?.configuration?.color} !important;
      }
    `}

  @media only screen and (max-width: 1045px) {
    font-size: 0.8rem !important;
  }

  @media only screen and (max-width: 767px) {
    background-color: inherit !important;
  }
  margin-right: -1rem !important;
`;

export const StyledMobileSidebarPusher = styled(Sidebar.Pusher)`
  display: flex !important;
  flex-flow: column;
  height: 100%;
`;

export const StyledMobileSidebarSegment = styled(Segment)`
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
`;

export const HeaderMobileGridColumn = styled(Grid.Column)`
  padding: 0 !important;
`;

export const HeaderDiv = styled.div`
  background-color: ${(props) => props.configuration.header.background} !important;

  color: ${(props) => props.configuration.header.color} !important;
  display: flex !important;
  flex-flow: row;
  padding: 1rem;
  justify-content: space-between;
`;

export const ContentDiv = styled.div`
  flex-grow: 1;
`;

export const StyledMenuItem = styled(Menu.Item)`
  color: inherit !important;
  @media only screen and (max-width: 319px) and (min-width: 280px) {
    padding-left: 1rem !important;
  }
`;

export const MenuItemStyled = styled(Menu.Item)`
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: inherit !important;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
  }
`;

export const StyledDropDownMenu = styled(Dropdown.Menu)`
  &&& > .item {
    color: ${(props) => props.configuration.header.background} !important;
  }
  &&& > .item:hover {
    background-color: ${(props) => props.configuration.header.selected} !important;
  }
`;

export const StyledDropDownText = styled(Dropdown)`
  &&& > .text {
    ${greetingStyle}
  }
  &&& .icon {
    @media only screen and (max-width: 766px) and (min-width: 280px) {
      padding-left: 0.5rem;
    }
    ${(props) =>
      !props?.user &&
      css`
        @media only screen and (max-width: 990px) and (min-width: 767px) {
          padding-left: 1.3rem;
        }
      `}

    vertical-align: top;
  }
  ${(props) =>
    !props?.user &&
    css`
      @media only screen and (max-width: 990px) and (min-width: 767px) {
        margin-left: -2rem;
      }
    `}
`;

export const StyledMobileSidebar = forwardAsProp(styled(Sidebar)`
  background-color: ${(props) => props.configuration.header.background} !important;

  color: ${(props) => props.configuration.header.color} !important;
  overflow: hidden !important;
  min-width: fit-content;
  ${(props) =>
    props?.configuration?.header?.selected &&
    css`
      &.ui.inverted.menu .active.item {
        color: ${props.configuration.header.selected} !important;
        background-color: inherit !important;
      }
    `}
`);
