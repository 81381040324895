import React from 'react';
import Localize from 'react-intl-universal';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';

import EmployeeGridList from '@components/EmployeeGridListView/EmployeeGridList/EmployeeGridList';
import Pagination from '@components/Pagination/Pagination';
import {
  CatalogContentColumnContainer,
  CatalogContentListRowContainer,
  CatalogContentRowContainer,
  CatalogGridColumnContainer,
  CatalogListContainer,
  CatalogPaginationContainer,
  CatalogSearchColumnContainer,
  CatalogSearchRowContainer
} from '@components/StyledComponents/Catalog.styled';
import style from '@pages/Catalog/style/Catalog.module.scss';

const EmployeesTab = (props) => {
  const {
    contextData,
    searchCompRef,
    searchMobRef,
    fetchEmployees,
    isLoading,
    setIsLoading,
    totalPages,
    employees,
    currentPage,
    setCurrentPage,
    setSearchTerm
  } = props;

  const onSearchFieldChange = (e) => {
    if (e.key === 'Enter') {
      // Enter is pressed by user
      setIsLoading(true);
      setCurrentPage(1);
      fetchEmployees();
    } else {
      const reg = /'/g;
      // eslint-disable-next-line quotes
      setSearchTerm(e.target.value.replace(reg, "''").trim());
    }
  };

  const getContent = () => {
    return (
      <EmployeeGridList
        employees={employees}
        configuration={contextData?.configuration?.TeamAreaEmployeeItem}
        listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
      />
    );
  };

  return (
    <>
      <CatalogGridColumnContainer
        data-test-id={'employeesTabTeamAreaCatalogGridColumnContainer'}
        computer={16}
        largeScreen={16}
        widescreen={16}
        tablet={16}
        mobile={16}
      >
        {/* desktop && tablet layout*/}
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        {/* desktop && tablet layout - menu and content*/}
        <CatalogListContainer className={'tablet computer only'} centered>
          <CatalogContentColumnContainer computer={16} largeScreen={16} widescreen={16} tablet={16}>
            <Sidebar.Pushable>
              <Sidebar.Pusher dimmed={false}>
                <Grid stackable container centered className={style.CatalogDesktopGrid}>
                  <CatalogContentRowContainer>
                    <div className={style.filterHeader}>
                      <Grid columns={16}>
                        <CatalogSearchColumnContainer
                          computer={16}
                          largeScreen={16}
                          widescreen={16}
                          tablet={16}
                        >
                          <Input
                            fluid
                            icon='search'
                            iconPosition='left'
                            placeholder={Localize.get('TeamArea.searchPlaceholder')}
                            onKeyUp={(e) => onSearchFieldChange(e)}
                            ref={searchCompRef}
                          />
                        </CatalogSearchColumnContainer>
                      </Grid>
                    </div>
                  </CatalogContentRowContainer>
                  <CatalogContentListRowContainer id='team-area-employee-content-row'>
                    {getContent()}
                  </CatalogContentListRowContainer>
                </Grid>
                <CatalogPaginationContainer centered className={style.TeamAreaPaginationDesktop}>
                  {!!totalPages && (
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                    />
                  )}
                </CatalogPaginationContainer>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </CatalogContentColumnContainer>
        </CatalogListContainer>

        {/* mobile layout - content*/}
        <CatalogListContainer className={'mobile only'} centered>
          <Grid.Column width={15}>
            <CatalogSearchRowContainer>
              <Input
                fluid
                icon='search'
                iconPosition='left'
                placeholder={Localize.get('TeamArea.searchPlaceholder')}
                onKeyUp={(e) => onSearchFieldChange(e)}
                ref={searchMobRef}
              />
            </CatalogSearchRowContainer>
            <Grid.Row>{getContent()}</Grid.Row>
            <Grid.Row centered>
              <CatalogPaginationContainer centered>
                {!!totalPages && (
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    noSiblings={totalPages > 3}
                  />
                )}
              </CatalogPaginationContainer>
            </Grid.Row>
          </Grid.Column>
        </CatalogListContainer>
      </CatalogGridColumnContainer>
    </>
  );
};

export default EmployeesTab;
