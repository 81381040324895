export const tabOptions = {
  information: 'Information',
  agenda: 'Agenda',
  participants: 'Participants',
  hotel: 'Hotel',
  attachments: 'Attachments',
  notes: 'Notes'
};

export const notesTypes = {
  agenda: { name: 'Agenda', key: 'AGENDA' },
  trainer: { name: 'Trainer Note', key: 'TRAINER_NOTE' }
};

export const customFooterSelectItems = [
  'none',
  'markComplete',
  'markIncomplete',
  'markAttended',
  'markAbsent'
];
