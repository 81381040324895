import { useState, useRef } from 'react';
import Localize from 'react-intl-universal';

import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';

import styles from './style/DragAndDropFile.module.scss';

const DragDropFiles = ({ config }) => {
  const [file, setFile] = useState(null);
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFile(event.dataTransfer.files[0]);
  };

  const handleButton = (event) => {
    event.preventDefault();
    setFile(event.target.files[0]);
  };

  return (
    <>
      <Segment
        data-testid={'allSessionDragAndDropSegment'}
        className={styles.dropzone}
        placeholder
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {file?.name ? (
          <>
            <p>{file.name}</p>
          </>
        ) : (
          <>
            <Header>{Localize.get('TrainerAreaAttachmentTable.dragAndDrop')}</Header>
            <input
              type='file'
              multiple
              onChange={handleButton}
              hidden
              accept={config?.configuration?.AttachmentTabUploadAcceptType}
              ref={inputRef}
            />
            <Button
              basic
              color='black'
              icon
              labelPosition='right'
              onClick={() => inputRef.current.click()}
            >
              <Icon basic='true' name='upload' className={styles.uploadIcon} />
              {Localize.get('TrainerAreaAttachmentTable.upload')}
            </Button>
          </>
        )}
      </Segment>
    </>
  );
};

export default DragDropFiles;
