import React from 'react';
import Localize from 'react-intl-universal';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';

import {
  CatalogContentColumnContainer,
  CatalogContentListRowContainer,
  CatalogContentRowContainer,
  CatalogGridColumnContainer,
  CatalogListContainer,
  CatalogSearchColumnContainer,
  CatalogSearchRowContainer
} from '@components/StyledComponents/Catalog.styled';
import style from '@pages/Catalog/style/Catalog.module.scss';
import EmployeesCoursesTable from '@pages/TeamArea/Tabs/SubTabs/EmployeesCoursesTable/EmployeesCoursesTable';

const CoursesTab = (props) => {
  const {
    employeesCoursesData,
    employeesWithCoursesCount,
    setEmployeesWithCoursesCount,
    isLoading,
    setIsLoading,
    fetchEmployeesCourses,
    setSearchTerm,
    setSortingOption,
    filterOption,
    setFilterOption,
    currentPage,
    setCurrentPage,
    totalPagesEmp,
    searchCompRef,
    searchMobRef
  } = props;

  const onSearchFieldChange = (e) => {
    if (e.key === 'Enter') {
      // Enter is pressed by user
      setIsLoading(true);
      setCurrentPage(1);
      fetchEmployeesCourses();
    } else {
      const reg = /'/g;
      // eslint-disable-next-line quotes
      setSearchTerm(e.target.value.replace(reg, "''").trim());
    }
  };

  return (
    <>
      <CatalogGridColumnContainer
        data-test-id={'coursesTabTeamAreaCatalogGridColumnContainer'}
        computer={16}
        largeScreen={16}
        widescreen={16}
        tablet={16}
        mobile={16}
      >
        {/* desktop && tablet layout*/}
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        {/* desktop && tablet layout - menu and content*/}
        <CatalogListContainer className={'tablet computer only'} centered>
          <CatalogContentColumnContainer computer={16} largeScreen={16} widescreen={16} tablet={16}>
            <Sidebar.Pushable>
              <Sidebar.Pusher dimmed={false}>
                <Grid stackable container centered className={style.CatalogDesktopGrid}>
                  <CatalogContentRowContainer>
                    <div className={style.filterHeader}>
                      <Grid columns={16}>
                        <CatalogSearchColumnContainer
                          computer={16}
                          largeScreen={16}
                          widescreen={16}
                          tablet={16}
                        >
                          <Input
                            fluid
                            icon='search'
                            iconPosition='left'
                            placeholder={Localize.get('Catalog.searchPlaceholder')}
                            onKeyUp={(e) => onSearchFieldChange(e)}
                            ref={searchCompRef}
                          />
                        </CatalogSearchColumnContainer>
                      </Grid>
                    </div>
                  </CatalogContentRowContainer>
                  <CatalogContentListRowContainer
                    id='team-area-courses-content-row'
                    className={style.EmployeesCoursesTable}
                  >
                    <EmployeesCoursesTable
                      isLoading={isLoading}
                      isMobile={false}
                      totalPages={totalPagesEmp}
                      totalElements={employeesWithCoursesCount}
                      setTotalElements={setEmployeesWithCoursesCount}
                      data={employeesCoursesData}
                      fetchEmployeesCourses={fetchEmployeesCourses}
                      setSortingOption={setSortingOption}
                      filterOption={filterOption}
                      setFilterOption={setFilterOption}
                      currentPage={currentPage}
                    />
                  </CatalogContentListRowContainer>
                </Grid>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </CatalogContentColumnContainer>
        </CatalogListContainer>

        {/* mobile layout - content*/}
        <CatalogListContainer className={'mobile only'} centered>
          <Grid.Column width={15}>
            <CatalogSearchRowContainer>
              <Input
                fluid
                icon='search'
                iconPosition='left'
                placeholder={Localize.get('TeamArea.searchPlaceholder')}
                onKeyUp={(e) => onSearchFieldChange(e)}
                ref={searchMobRef}
              />
            </CatalogSearchRowContainer>
            <CatalogContentListRowContainer
              id='team-area-courses-content-row-mobile'
              className={style.EmployeesCoursesTable}
            >
              <EmployeesCoursesTable
                isLoading={isLoading}
                isMobile={true}
                totalPages={totalPagesEmp}
                totalElements={employeesWithCoursesCount}
                setTotalElements={setEmployeesWithCoursesCount}
                data={employeesCoursesData}
                fetchEmployeesCourses={fetchEmployeesCourses}
                setSortingOption={setSortingOption}
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                currentPage={currentPage}
              />
            </CatalogContentListRowContainer>
          </Grid.Column>
        </CatalogListContainer>
      </CatalogGridColumnContainer>
    </>
  );
};

export default CoursesTab;
