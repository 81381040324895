import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import get from 'lodash/get';
import truncate from 'lodash/truncate';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import { httpStatusCodes } from '@common/Constants';
import Logger from '@common/Logger.js';
import { formatDateTimeStringToShortMonthDate } from '@common/Util';
import { initialSnackbarState, errorSnackbarState } from '@components/Snackbar/Constants';
import CustomizedSnackbar from '@components/Snackbar/Snackbar';
import useFetching from '@hooks/Fetching/Fetching';
import GetAppIcon from '@mui/icons-material/GetApp';
import TextField from '@mui/material/TextField';

import TableButton from '../../../components/TableButton/TableButton';
import { createParticipantService, createParticipantsExportService } from '../AllSessionHelper';
import { editParticipantService } from '../MainHelper';
import SimpleTable from '../SimpleTable';

export const TableTextField = () => {
  return <TextField data-testid={'tableTextField'} id='result' defaultValue='0' size='small' />;
};

const Participants = (props) => {
  const { match, searchCompRef, sessionDaysData = [], config, iltSessionName } = props;

  //--- for dataGrid
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const [sessionID] = useState(match?.params?.id ? match.params.id : '');
  const [participants, setParticipants] = useState([]);
  const [switchLoader, setSwitchLoader] = React.useState({ loader: false, id: null, name: '' });
  const [registrationSnackbarState, setRegistrationSnackbarState] = useState(initialSnackbarState);
  const [simpleTableColumns, setSimpleTableColumns] = useState(
    config?.configuration?.AllSessionParticipantsTabFields
  );
  const [fileType, setFileType] = useState();

  const setRegistrationSnackbarToInit = () => {
    setRegistrationSnackbarState(initialSnackbarState);
  };

  const callBackError = (error) => {
    setRegistrationSnackbarState({
      ...errorSnackbarState,
      message: error?.message ? error.message : Localize.get('Tables.Error')
    });
  };

  const [participantsData, isLoading, , fetchParticipants] = useFetching(
    createParticipantService.bind(
      null,
      sessionID,
      paginationModel.page + 1,
      paginationModel.pageSize
    ),
    true
  );

  const [attachmentDownloadData, , , fetchAttachmentDownloadData] = useFetching(
    createParticipantsExportService.bind(null, sessionID, fileType, iltSessionName, callBackError),
    true
  );

  const handleButtonChange = async ({ target }) => {
    const participantId = target.id;
    const headerName = target.name; //sessionDayId or name
    let sessionDayId = sessionDaysData[0]?.id ? sessionDaysData[0]?.id : null;
    let body;
    let selectedParticipant = participants.find(
      (participant) => participant.id.toString() === participantId
    );
    setSwitchLoader({ loader: true, id: participantId, name: headerName });
    switch (headerName) {
      case 'completion':
        body = {
          eventParticipant: {
            completed: target.checked
          },
          attended: null
        };
        break;
      default:
        sessionDayId = target.name;
        body = {
          eventParticipant: {
            completed: selectedParticipant?.completed
          },
          attended: target.checked
        };
    }
    const response = await editParticipantService(
      sessionID,
      participantId,
      sessionDayId,
      body,
      callBackError
    );
    if (response?.status === httpStatusCodes.ok) {
      switch (headerName) {
        case 'completion':
          setParticipants(
            participants.map((participant) =>
              participant.id === selectedParticipant?.id
                ? { ...selectedParticipant, completion: response?.data?.data?.completed }
                : participant
            )
          );
          break;
        default:
          setParticipants(
            participants.map((participant) =>
              participant.id === selectedParticipant?.id
                ? {
                    ...selectedParticipant,
                    [headerName]: response?.data?.data?.sessions[0]?.attended
                  }
                : participant
            )
          );
      }
    }
    setSwitchLoader({ loader: false, id: participantId, name: headerName });
  };

  const formatResponse = (res) => {
    return res.map((el) => {
      let sessionsArr = el.SESSIONS.map((el) => ({
        id: el.session.id,
        attended: el.attended
      }));
      let obj = {
        id: get(el, 'ILT_SESSION_PARTICIPANT.id'),
        name: get(el, 'ILT_SESSION_PARTICIPANT.personDto.name'),
        company: get(el, 'ILT_SESSION_PARTICIPANT.company.name'),
        email: el?.ILT_SESSION_PARTICIPANT.emailDtos.find((el) => el.type.id === 2)?.email,
        completion: get(el, 'ILT_SESSION_PARTICIPANT.completed'),
        status: get(el, 'ILT_SESSION_PARTICIPANT.status.value')
      };
      sessionsArr.forEach((session) => {
        obj[session.id] = session.attended;
      });
      return obj;
    });
  };

  let variableColumns = sessionDaysData.map((el) => ({
    field: el?.id.toString(),
    headerName: `${formatDateTimeStringToShortMonthDate(el?.dayDate)}, ${el.startTime}`,
    editable: false,
    headerAlign: 'left',
    width: 150,
    variableColumn: true,
    id: el?.id
  }));

  useEffect(() => {
    let columns = simpleTableColumns.map((el) => ({ ...el, variableColumn: false }));
    columns.splice(3, 0, ...variableColumns);
    setSimpleTableColumns(columns);
    columns = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionDaysData]);

  useEffect(() => {
    if (sessionID?.length) {
      fetchParticipants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionID, paginationModel]);

  useEffect(() => {
    if (participantsData) {
      setParticipants(formatResponse(participantsData?.data?.participants?.PARTICIPANTS_CONTENT));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantsData]);

  useEffect(() => {
    const downloadFile = async () => {
      try {
        if (attachmentDownloadData?.data) {
          const fileNameFull = attachmentDownloadData.headers['content-disposition'];
          const fileName = fileNameFull.split('=');
          const objectUrl = window.URL.createObjectURL(
            new Blob([attachmentDownloadData.data], {
              type: attachmentDownloadData.headers['content-type']
            })
          );
          const link = document.createElement('a');
          link.href = objectUrl;
          link.setAttribute('download', fileName[1]);
          document.body.appendChild(link);
          link.click();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    downloadFile();
  }, [attachmentDownloadData]);

  const onSearchFieldChange = () => {}; // TO DO --- use this func after BE is finished

  const handleDownloadClick = (item) => {
    setFileType(item);
    fetchAttachmentDownloadData();
  };

  const getElement = (params, column) => {
    switch (params.field) {
      case 'completion':
        return (
          <TableButton
            params={params}
            name={'completion'}
            handleButtonChange={handleButtonChange}
            switchLoader={switchLoader}
          />
        );
      case 'result':
        return <TableTextField params={params} />;

      default:
        return column.variableColumn ? (
          <TableButton
            name={column?.id}
            params={params}
            handleButtonChange={handleButtonChange}
            switchLoader={switchLoader}
          />
        ) : (
          truncate(get(params.row, params.field))
        );
    }
  };

  return (
    <>
      <Grid.Column width={14} data-testid={'allSessionParticipantTab'}>
        {/* desktop && tablet layout*/}
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        <Input
          disabled
          fluid
          icon='search'
          iconPosition='left'
          placeholder={Localize.get('Catalog.searchPlaceholder')}
          onKeyUp={(e) => onSearchFieldChange(e)}
          ref={searchCompRef}
        />

        {/* desktop && tablet layout - menu and content*/}

        <SimpleTable
          data={participants}
          columns={[
            ...simpleTableColumns.map((column) => ({
              ...column,
              headerName: column.variableColumn
                ? column.headerName
                : Localize.get(column.headerName),
              renderCell: (params) => (
                <div data-testid={`${params.field}-${params?.row?.id ?? 'default'}`}>
                  {getElement(params, column)}
                </div>
              )
            }))
          ]}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          isLoading={isLoading}
          elementsCount={participantsData?.data?.participantsCount}
          config={config}
          showSelect={true}
          showButton={true}
          buttonConfig={{
            text: 'TrainerAreaParticipantsTable.downloadList',
            icon: <GetAppIcon />,
            disabled: participants?.length === 0,
            isMenuBtn: true
          }}
          onMenuClick={handleDownloadClick}
        />
        <CustomizedSnackbar
          open={registrationSnackbarState?.open}
          handleClose={setRegistrationSnackbarToInit}
          status={registrationSnackbarState?.status}
          message={registrationSnackbarState?.message}
        />
      </Grid.Column>
    </>
  );
};

export default Participants;
