export const deviceSize = {
  mobile: 'Mobile',
  computer: 'Computer',
  tablet: 'Tablet'
};

export const supportedAvatarImageExtension = [
  'apng',
  'gif',
  'ico',
  'cur',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'svg'
];

export const supportedAttachmentTypes = {
  image: ['jpg', 'jpeg', 'png', 'gif'],
  svg: ['svg'],
  pdf: ['pdf'],
  excel: ['xls', 'xlsx'],
  ppt: ['ppt', 'pptx'],
  doc: ['doc', 'docx'],
  video: ['mp4'],
  corel: ['ai', 'eps'],
  zip: ['zip']
};

export const eventNoteTypes = {
  note: 'TT0008',
  abstract: 'TT0017'
};

export const confirmationCodes = {
  confirm: 'confirm'
};

export const defaultThumbnail = {
  name: 'default'
};

export const courseDescriptionType = {
  course: 'course',
  module: 'module',
  learningPath: 'learningPath'
};

export const entityTypes = {
  learningPath: 'learningPath',
  course: 'course'
};

// To DO:
// This will be logged in user once we have token
export const initParticipantDetails = {
  bookingRole: ['manager'],
  customerNo: '1000258',
  contact: 'Alex Baiser',
  userName: 'tobias.rothley@contrimo.com',
  hotelsRequired: [],
  dates: [],
  acceptParticipation: false,
  privacyPolicyMessage: false,
  termsAndConditions: false,
  iltModulesSessions: [],
  participants: []
};

export const httpStatusCodes = {
  ok: 200,
  badRequest: 400,
  notAuthenticated: 401,
  forbidden: 403,
  notFound: 404,
  invalidData: 422,
  internalServerError: 500
};

export const courseType = {
  course: 'Course',
  eLearning: 'ELearning',
  learningPath: 'LearningPath'
};

export const courseDetailsType = {
  assigned: 'Assigned course details',
  unassigned: 'Unassigned course details'
};

export const registrationStatuses = {
  toBeInvited: 'TBIN',
  completed: 'COMP',
  reserved: 'RESV',
  confirmed: 'CONF',
  cancelled: 'CANC',
  registered: 'REG',
  invited: 'INVI',
  waitingCancelled: 'WCAN',
  notCompleted: 'NCOMP'
};

export const bookingStatus = {
  successful: 'Green',
  waitingList: 'Yellow',
  decline: 'Red'
};

export const iltSessionsStatus = {
  registered: 'Registered',
  available: 'Available',
  past: 'Past'
};

export const iltPricesTypeKey = {
  standardPrice: 'STANDARD',
  advertisingSubsidy: 'ADVERTISING_SUBSIDY'
};

export const iltPricesCurrency = {
  eur: 'EUR'
};
