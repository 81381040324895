import React, { useState } from 'react';
import Localize from 'react-intl-universal';

import { useGlobalContext } from '@hooks/Context/GlobalContext';

import style from './style/LearningPathDetails.module.scss';

const LearningPathDescription = (props) => {
  const { description } = props;

  const [contextData] = useGlobalContext();

  const maxDescriptionWords =
    contextData?.configuration?.LearningPathDetailsContent?.maxLearningPathDescriptionWords;

  const initialShowFullDescription = description && description.length <= maxDescriptionWords;
  const [showFullDescription, setShowFullDescription] = useState(initialShowFullDescription);

  const toggleShowAllDescription = () => {
    setShowFullDescription((prevShowFullDesc) => !prevShowFullDesc);
  };

  return (
    <>
      {description && description.length ? (
        <div data-testid={'learningPathDescriptionContent'}>
          {showFullDescription ? (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: description.slice(0, maxDescriptionWords) + '...'
              }}
            />
          )}
          {description.length > maxDescriptionWords && (
            <span
              className={style.showMoreLink}
              onClick={toggleShowAllDescription}
              onKeyDown={toggleShowAllDescription}
              onKeyUp={toggleShowAllDescription}
              role='button'
              tabIndex={0}
            >
              {showFullDescription ? Localize.get('App.less') : Localize.get('App.more')}
            </span>
          )}
        </div>
      ) : (
        <div data-testid={'noLearningPathDescriptionContent'}>
          {Localize.get('CourseDetails.noDesc')}
        </div>
      )}
    </>
  );
};

export default LearningPathDescription;
