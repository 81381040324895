/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';
import { Link } from 'react-router-dom';

// semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import * as globalConstants from '@common/Constants.js';
import * as countriesCodes from '@common/Countries.js';
import Logger from '@common/Logger.js';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import useFetching from '@hooks/Fetching/Fetching';
import * as routerConstants from '@navigation/Constants.js';
import Attachments from '@pages/AllSession/Tabs/Attachments';
import { courseSessionType } from '@pages/CourseDetails/Constants.js';

import * as constants from './Constants';
import DetailsMenuItems from './DetailsMenuItems.jsx';
import DetailsMobileMenu from './DetailsMobileMenu';
import styles from './style/AllSessionDetails.module.scss';
import Agenda from './Tabs/Agenda';
import Hotel from './Tabs/Hotel';
import Information from './Tabs/Information.jsx';
import Notes from './Tabs/Notes.jsx';
import Participants from './Tabs/Participants';
import { createEventService } from '../EventDetails/EventDetailsHelper.js';
import RegistrationEvent from '../EventDetails/RegistrationEvent/RegistrationEvent.jsx';
import {
  createILTSessionAgendaService,
  getInstructorsNamesFromSessionDays,
  getLocationInfoFromSessionDays
} from '../EventDetails/Tabs/AgendaHelper';

const AllSessionDetails = ({ match, history }) => {
  const [registrationActive, setRegistrationActive] = useState(false);
  const [activeItem, setActiveItem] = useState(constants.tabOptions.information);
  const [iltSessionID, setIltSessionID] = useState(
    match && match.params && match.params.id ? match.params.id : ''
  );
  let previousPath = '';
  let previousPathName = '';
  const [contextData] = useGlobalContext();
  const [iltSession, setILTSession] = useState({});
  const [iltSessionSessionDays, setILTSessionSessionDays] = useState([]);

  const redirectToHome = () => {
    return history.push({
      pathname: '/',
      state: {
        from: 'Home',
        path: '/'
      }
    });
  };

  const [iltSessionData, isLoading, , fetchILTSession] = useFetching(
    createEventService.bind(null, iltSessionID, 'iltSession', redirectToHome),
    true
  );
  const [sessionDaysData, isLoadingDays, , fetchSessionDays] = useFetching(
    createILTSessionAgendaService.bind(null, iltSessionID),
    true
  );

  // --- TO DO --- delete this data after BE is finished
  const hardcodedHotelData = {
    checkIn: '10.12.2024',
    checkOut: '20.12.2024',
    assignedHotel: 'Hayat Regency',
    roomCategory: 'Two bedroom apartment',
    address: 'Hotel Street 10 72122 Ruesheim',
    services: 'Breakfast included',
    facilities: 'Swimming Pool',
    checkInTimesStart: '3:00 pm',
    checkInTimesEnd: '10:00 pm',
    checkOutTime: '11:00 am',
    brekfastStart: '7:00 am',
    brekfastEnd: '10:00 am',
    additionalInformation: 'XYZ',
    other: 'XYZ'
  };

  useEffect(() => {
    if (iltSessionID && iltSessionID?.length) {
      fetchILTSession();
      fetchSessionDays();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iltSessionID]);

  useEffect(() => {
    const fetchILTSession = async () => {
      try {
        if (iltSessionData?.data) {
          setILTSession(iltSessionData.data);
        }
        if (sessionDaysData?.data?.content) {
          setILTSessionSessionDays(
            sessionDaysData.data.content.sort((a, b) => new Date(a.dayDate) - new Date(b.dayDate))
          );
          iltSession.instructors = getInstructorsNamesFromSessionDays(sessionDaysData.data.content);
          iltSession.locationInfo =
            iltSession?.type?.key === courseSessionType.webinar
              ? Localize.get('App.online')
              : getLocationInfoFromSessionDays(sessionDaysData.data.content);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchILTSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iltSessionData, sessionDaysData]);

  useEffect(() => {
    if (!iltSessionID && match?.params?.id) {
      setIltSessionID(match.params.id);
    }
  }, [iltSessionID, match]);

  const handleActiveComponent = (component) => {
    let setComponent;
    switch (component) {
      case constants.tabOptions.information:
        setComponent = (
          <Information
            iltSession={iltSession}
            config={contextData}
            isLoading={isLoadingDays}
            notesData={iltSession.notes}
          />
        );
        break;
      case constants.tabOptions.agenda:
        setComponent = <Agenda sessionsData={iltSessionSessionDays} />;
        break;
      case constants.tabOptions.attachments:
        setComponent = <Attachments match={match} config={contextData} />;
        break;
      case constants.tabOptions.notes:
        setComponent = (
          <Notes notesData={iltSession.notes} config={contextData} isLoading={isLoadingDays} />
        );
        break;
      case constants.tabOptions.hotel:
        setComponent = (
          <Hotel
            hotelData={hardcodedHotelData}
            config={contextData}
            isLoading={isLoadingDays}
            notesData={iltSession.notes}
          />
        );
        break;
      case constants.tabOptions.participants:
        setComponent = (
          <Participants
            match={match}
            sessionDaysData={sessionDaysData?.data?.content}
            config={contextData}
            iltSessionName={iltSession?.name}
          />
        );
        break;
      default:
        setComponent = (
          <Information
            iltSession={iltSession}
            config={contextData}
            isLoading={isLoadingDays}
            notesData={iltSession.notes}
          />
        );
        break;
    }
    return setComponent;
  };

  const handleItemClick = (e, data) => {
    setActiveItem(data.name);
  };

  const handleLinkClick = () => {
    history.push({
      pathname: previousPath,
      state: {
        from: previousPathName.replace('/', '').trim(),
        path: previousPath
      }
    });
  };

  if (history?.location?.state?.from) {
    previousPathName = history.location.state.from + ' /';
    previousPath = history.location.state.path;
  }

  return (
    <>
      {/* desktop && tablet layout*/}
      <Grid centered>
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        {/* desktop && tablet layout - link*/}
        {previousPath && previousPathName && (
          <Grid.Row className={`tablet computer only ${styles.link}`}>
            <Grid.Column computer={14} tablet={14} widescreen={14} textAlign='right'>
              <Form.Group className={styles.linkContainer}>
                <Form.Field className={styles.linkPart}>
                  <Link
                    to={{
                      pathname: history?.location?.state?.path,
                      state: {
                        from: 'showOverview'
                      }
                    }}
                  >
                    {Localize.get(
                      `TrainerArea${history?.location?.state?.path.replace(/\//g, '.')}`
                    )}
                    /
                  </Link>
                </Form.Field>
                <Form.Field className={styles.linkPart}>
                  <Link
                    to={{
                      pathname: previousPath,
                      state: {
                        from: previousPathName.replace('/', '').trim(),
                        path: previousPath,
                        activeIndex: 1
                      }
                    }}
                  >
                    {previousPathName}
                  </Link>
                </Form.Field>
                <Form.Field>
                  <div role='link' onClick={handleLinkClick} onKeyDown={() => {}} tabIndex={0}>
                    {Localize.get('EventDetails.detailsInfo')}
                  </div>
                </Form.Field>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
        )}

        {/* desktop && tablet layout - message*/}
        <Grid.Column
          computer={14}
          largeScreen={14}
          widescreen={14}
          tablet={14}
          className={styles.message}
          only='computer tablet'
        >
          <Message
            data-testid={'allSessionDetailsMessage'}
            style={{
              background: contextData?.configuration?.CourseDetailsMessage.background,
              boxShadow: contextData?.configuration?.CourseDetailsMessage.boxShadow
            }}
          >
            <Message.Header className={styles.messageHeaderStyle}>{iltSession.name}</Message.Header>
            <Message.Content className={styles.messageHeaderContainer}>
              <Grid relaxed className={styles.messageHeaderContainerGrid}>
                <Grid.Column className={styles.messageTypeContainer}>
                  {iltSession?.type?.key}
                </Grid.Column>
                <Grid.Column className={styles.messageCountryContainer}>
                  <Flag
                    name={
                      iltSession && iltSession.nativeLanguage
                        ? countriesCodes.countryISOCodeByCountry[
                            iltSession.nativeLanguage.key.toLowerCase()
                          ]
                        : ''
                    }
                  />
                </Grid.Column>
              </Grid>
            </Message.Content>
          </Message>
        </Grid.Column>
      </Grid>

      {/* desktop && tablet layout - menu and content*/}
      {!registrationActive &&
        (contextData?.size?.device === globalConstants.deviceSize.computer ||
          contextData?.size?.device === globalConstants.deviceSize.tablet) && (
          <Grid centered className={`${styles.gridMargin}`}>
            <Grid.Column
              style={{ background: contextData?.configuration?.CourseDetailsContent.background }}
              className={styles.columnPadding}
              computer={14}
              largeScreen={14}
              widescreen={14}
              tablet={14}
            >
              <Menu pointing secondary widths={6} className={styles.menuTabletResolution}>
                <DetailsMenuItems
                  activeItem={activeItem}
                  handleItemClick={handleItemClick}
                  showIcon={contextData.size.device === globalConstants.deviceSize.computer}
                  config={contextData?.configuration}
                />
              </Menu>

              {handleActiveComponent(activeItem)}
            </Grid.Column>
          </Grid>
        )}

      {registrationActive &&
        (contextData?.size?.device === globalConstants.deviceSize.computer ||
          contextData?.size?.device === globalConstants.deviceSize.tablet) && (
          <Grid
            className={`tablet computer only ${styles.menuCenter}`}
            centered
            style={{ background: contextData?.configuration?.CourseDetailsContent.background }}
          >
            <RegistrationEvent event={iltSession} setRegistrationActive={setRegistrationActive} />
          </Grid>
        )}

      {/* mobile layout */}
      <Grid centered container className={`mobile only ${styles.gridMobileTop}`}>
        {/* mobile layout - link */}
        <Grid.Row className={styles.navLinkContainerMobile}>
          <Form.Group className={styles.linkContainer}>
            <Form.Field className={styles.linkPart}>
              <Link
                to={{
                  pathname: routerConstants.routes.path.trainerArea,
                  state: {
                    from: 'showOverview'
                  }
                }}
              >
                {Localize.get('TrainerArea.trainerArea')} /
              </Link>
            </Form.Field>
            <Form.Field className={styles.linkPart}>
              <Link
                to={{
                  pathname: previousPath,
                  state: {
                    from: previousPathName.replace('/', '').trim(),
                    path: previousPath
                  }
                }}
              >
                {previousPathName}
              </Link>
            </Form.Field>
            <Form.Field>
              <div
                data-testid={'allSessionDetailsLink'}
                role='link'
                onClick={handleLinkClick}
                onKeyDown={() => {}}
                tabIndex={0}
              >
                {Localize.get('EventDetails.detailsInfo')}
              </div>
            </Form.Field>
          </Form.Group>
        </Grid.Row>

        {/* mobile layout - message*/}
        <Grid.Row only='mobile' centered className={styles.messageMobileContainer}>
          <Grid.Column width={16}>
            <Message
              style={{
                background: contextData?.configuration?.CourseDetailsMessage.background,
                boxShadow: contextData?.configuration?.CourseDetailsMessage.boxShadow
              }}
            >
              <Message.Header className={styles.messageHeaderStyle}>
                {iltSession.name}
              </Message.Header>
              <Message.Content className={styles.messageHeaderContainer}>
                <Grid>
                  <Grid.Column className={styles.messageTypeContainerMobile}>
                    {iltSession?.type?.key}{' '}
                  </Grid.Column>
                  <Grid.Column>
                    <Flag
                      name={
                        iltSession && iltSession.nativeLanguage
                          ? countriesCodes.countryISOCodeByCountry[
                              iltSession.nativeLanguage.key.toLowerCase()
                            ]
                          : ''
                      }
                    />
                  </Grid.Column>
                </Grid>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>

        {!registrationActive && (
          <Grid.Row only='mobile' centered>
            <Grid.Column width={14} stretched className={styles.menuMobileCenter}>
              <DetailsMobileMenu
                activeItem={activeItem}
                handleItemClick={handleItemClick}
                config={contextData?.configuration}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        {/* mobile layout - content*/}
        {!registrationActive &&
          contextData &&
          contextData.size &&
          contextData.size.device === globalConstants.deviceSize.mobile && (
            <Grid
              stackable
              container
              centered
              className={`mobile only ${styles.tabContainer}`}
              style={{ background: contextData?.configuration?.CourseDetailsContent.background }}
            >
              <Grid.Column width={12}>{handleActiveComponent(activeItem)}</Grid.Column>
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default AllSessionDetails;
