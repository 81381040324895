import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import get from 'lodash/get';
import truncate from 'lodash/truncate';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import { StyledTypography } from '@components/StyledComponents/TableHeading.styled';
import useFetching from '@hooks/Fetching/Fetching';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { createAttachmentServiceForIltSessions } from '../AllSessionHelper';
import AttachmentAvailableField from '../AttachmentAvailableField';
import AttachmentDescriptionField from '../AttachmentDescriptionField';
import AttachmentOptionsField from '../AttachmentOptionsField';
import SimpleTable from '../SimpleTable';
import UploadModal from '../UploadModal';

const Attachments = (props) => {
  const { match, config } = props;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const [sessionID] = useState(match?.params?.id ? match.params.id : '');
  const [attachments, setAttachments] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [attachmentsData, isLoading, , fetchAttachments] = useFetching(
    createAttachmentServiceForIltSessions.bind(null, sessionID),
    true
  );

  const handleOptions = (event) => {
    switch (event.currentTarget.name) {
      case 'download':
        // TO DO download file
        break;
      case 'edit':
        //TO DO edit file
        break;
      case 'delete':
        // TO DO delete file
        break;
      default:
    }
  };

  const onSearchFieldChange = () => {}; // TO DO --- use this func after BE is finished

  const getElement = (params) => {
    switch (params.field) {
      case 'descriptionField':
        return <AttachmentDescriptionField params={params?.value} />;
      case 'availableFor':
        return <AttachmentAvailableField params={params?.value} />;
      case 'options':
        return <AttachmentOptionsField params={params} handleOptions={handleOptions} />;
      default:
        return <StyledTypography>{truncate(get(params.row, params.field))}</StyledTypography>;
    }
  };

  const handleUploadModal = () => {
    setOpenModal(true);
  };

  const formatResponse = (attachmentsArray) => {
    return attachmentsArray?.map((attachment) => {
      return {
        id: attachment.id,
        descriptionField: {
          description: attachment.displayName
            ? attachment.displayName
            : Localize.get('TrainerAreaAttachmentTable.noDescription'),
          type: attachment.extension,
          fileName: attachment.name
        },
        availableFor: {
          visibleToAdministrators: attachment.visibleToAdministrators,
          visibleToLocationResponsible: attachment.visibleToLocationResponsible,
          visibleToParticipants: attachment.visibleToParticipants,
          visibleToTrainers: attachment.visibleToTrainers
        }
      };
    });
  };

  useEffect(() => {
    if (sessionID?.length) {
      fetchAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionID]);

  useEffect(() => {
    if (attachmentsData) {
      setAttachments(formatResponse(attachmentsData.data.attachments));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsData]);

  return (
    <>
      <>
        <Grid.Column width={14} data-testid={'allSessionAttachmentTab'}>
          {/* desktop && tablet layout*/}
          {isLoading && (
            <Dimmer active inverted>
              <Loader size='large'>{Localize.get('App.loading')}</Loader>
            </Dimmer>
          )}

          <Input
            disabled
            fluid
            icon='search'
            iconPosition='left'
            placeholder={Localize.get('Catalog.searchPlaceholder')}
            onKeyUp={(e) => onSearchFieldChange(e)}
          />

          {/* desktop && tablet layout - menu and content*/}

          <SimpleTable
            data={attachments}
            columns={[
              ...(config?.configuration?.AllSessionAttachmentTab).map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                renderCell: (params) => (
                  <div data-testid={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {getElement(params)}
                  </div>
                )
              }))
            ]}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            paginationMode={'client'}
            isLoading={isLoading}
            participantsCount={attachmentsData?.data?.participantsCount}
            config={config}
            showSelect={false}
            showButton={true}
            buttonConfig={{
              text: 'TrainerAreaAttachmentTable.upload',
              icon: <FileUploadIcon />,
              disabled: true,
              isMenuBtn: false
            }}
            onMenuClick={handleUploadModal}
          />
        </Grid.Column>
        <UploadModal open={openModal} setOpen={setOpenModal} config={config} />
      </>
    </>
  );
};

export default Attachments;
