import React, { useEffect, useState, Fragment } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import {
  courseDescriptionType,
  courseDetailsType as courseTypes,
  courseType
} from '@common/Constants';
import { checkIfUserIsAlreadyRegistered, isAfterToday } from '@common/Util';
import { useGlobalContext } from '@hooks/Context/GlobalContext';
import CourseDescription from '@pages/CourseDetails/Components/CourseDescription';
import SessionDescription from '@pages/CourseDetails/Components/SessionDescription';

import style from './style/SessionCard.module.scss';
import { moduleType } from '../Constants';

const ModuleDescription = (props) => {
  const { itemNo, module, course, config, courseDetailsType } = props;

  const [courseSessions, setCourseSessions] = useState(null);
  const [showAllSessions, setShowAllSessions] = useState(false);

  const [contextData] = useGlobalContext();

  const maxSessions =
    contextData?.configuration?.CourseDetailsContent?.maxNumberOfSessionDescriptions;

  let alreadyRegistered = false;

  useEffect(() => {
    if (course.COURSE_SESSIONS?.length) {
      setCourseSessions(
        course.COURSE_SESSIONS.filter((session) => session.module.id === module.ID).filter(
          (session) => isAfterToday(session.startDateTime)
        )
      );
    } else {
      setCourseSessions([]);
    }
  }, [course]);

  const toggleShowAllSessions = () => {
    setShowAllSessions((prevShowAllSessions) => !prevShowAllSessions);
  };

  const getModuleSessionDescription = () => {
    let sessionsToShow = [];
    const { userAlreadyRegistered, sessions } = checkIfUserIsAlreadyRegistered(courseSessions);
    alreadyRegistered = userAlreadyRegistered;
    if (courseDetailsType === courseTypes.assigned) {
      sessionsToShow =
        userAlreadyRegistered || showAllSessions ? sessions : sessions.slice(0, maxSessions);
    } else if (courseDetailsType === courseTypes.unassigned) {
      sessionsToShow = showAllSessions ? courseSessions : courseSessions.slice(0, maxSessions);
    }

    return sessionsToShow
      .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))
      .map((session, index) => (
        <SessionDescription
          key={index}
          itemNo={index}
          session={session}
          config={config}
          courseDetailsType={courseDetailsType}
          userAlreadyRegistered={userAlreadyRegistered}
        />
      ));
  };

  return (
    <Grid.Row
      data-testid={'courseModuleDescriptionDateContainer'}
      key={itemNo}
      style={{
        background: config?.configuration?.CourseDetailsInfoContent.background,
        border: config?.configuration?.CourseDetailsInfoContent.border
      }}
    >
      <Grid.Column width={16}>
        <h5>{module?.NAME}</h5>
        <CourseDescription description={module?.DESCRIPTION} type={courseDescriptionType.module} />
        {course?.COURSE_TYPE_NAME === Localize.get(`App.${courseType.course}`) &&
        module?.MODULE_TYPE_NAME === moduleType.iltModule &&
        courseSessions !== null ? (
          <Grid
            style={{
              paddingTop: config?.configuration?.CourseDetailsInfoContent?.paddingTop
            }}
            column={16}
          >
            <Grid.Row>
              <Grid.Column>
                <h5>{Localize.get('CourseDetails.possibleDatesAndLocations')}</h5>
              </Grid.Column>
            </Grid.Row>
            {courseSessions?.length ? (
              <Fragment>
                {getModuleSessionDescription()}
                {courseSessions.length > maxSessions && !alreadyRegistered && (
                  <span
                    className={style.showMoreLink}
                    onClick={toggleShowAllSessions}
                    onKeyDown={toggleShowAllSessions}
                    onKeyUp={toggleShowAllSessions}
                    role='button'
                    tabIndex={0}
                  >
                    {showAllSessions ? Localize.get('App.less') : Localize.get('App.more')}
                  </span>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Grid.Column width={16}>
                  <h5>{Localize.get('CourseDetails.noIltSessionsAvailable')}</h5>
                </Grid.Column>
                <Grid.Column width={1} />
                <Grid.Column width={3} textAlign='center' className={style.textBlock}>
                  {`${Localize.get('CourseDetails.pleaseContact')} `}
                  <span className={style.emailLink}>
                    {config?.configuration?.CourseDetailsContent.supportContactLink}
                  </span>
                </Grid.Column>
                <Grid.Row />
              </Fragment>
            )}
          </Grid>
        ) : (
          <></>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

export default ModuleDescription;
