import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';
import { List } from 'semantic-ui-react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import styled, { css } from 'styled-components';

const displayFlexRow = css`
  display: flex;
  flex-direction: row;
`;

const textOverflow = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const displayFlexRowWithColumnGap = css`
  ${displayFlexRow}
  column-gap: 0.5rem;
`;

export const OverviewGridContainer = styled(Grid)`
  margin-top: auto !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: inherit;
`;

export const OverviewGrid = styled(Grid)`
  margin-top: -2rem !important;
  @media only screen and (max-width: 990px) and (min-width: 767px) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    margin-top: auto !important;
  }

  @media only screen and (min-height: 400px) {
    margin-bottom: 1rem !important;
  }
`;

export const MainColumnContainer = styled(Grid.Column)`
  @media only screen and (max-width: 990px) and (min-width: 767px) {
    padding-left: inherit !important;
    padding-right: inherit !important;
  }
`;

export const RightColumnContainer = styled(Grid.Column)`
  padding-right: inherit !important;
`;

export const CalendarSegment = styled(Segment)`
  min-height: 100% !important;
`;

export const TodayButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-top: inherit;
  padding-right: 0.5rem;
  font-weight: bold;
  color: #003a7d;
  cursor: pointer;
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

export const TodayButton = styled.div`
  text-align: end;
  width: fit-content;
  font-weight: bold;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #003a7d;
  cursor: pointer;
  @media only screen and (min-width: 991px) {
    :hover {
      background-color: #003a7d10 !important;
      border-radius: 0.5rem;
    }
  }
`;

export const TodayDateIconContainer = styled.div`
  ${displayFlexRowWithColumnGap}
  padding-top: 0;
  margin-top: -0.313rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
  color: #7b7b7b;
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    padding-left: 1rem;
  }
`;

export const ListContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const SelectedDateEventListContent = styled.div`
  ${displayFlexRowWithColumnGap}
  color: #7b7b7b;
  margin-bottom: 0.3rem;
`;

export const SelectedDateEventDescription = styled.div`
  font-weight: bold;
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    ${textOverflow}
  }
`;

export const OverviewFilterContainer = styled.div`
  ${displayFlexRow}
  padding-top: 1rem;
  justify-content: center;
  column-gap: 1rem;
  color: #003a7d;
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    justify-content: start;
    padding-left: 1rem;
  }
`;

export const OverviewFilterSegment = styled(Segment)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    flex-direction: row;
    min-height: 7.425rem;
  }
`;

export const OverviewFilterSegmentMini = styled(Segment)`
  min-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    flex-direction: row;
    min-height: 3.425rem;
  }
`;

export const UpcomingEventsSegment = styled(Segment)`
  min-height: 69vh !important;
`;

export const UpcomingEventsTextContainer = styled.div`
  padding-left: 1rem !important;
  ${displayFlexRow}
  column-gap: 1rem;
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    column-gap: 0.3rem;
    padding-left: 0 !important;
  }
`;

export const UpcomingEventsListContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  min-height: 57vh;
  max-width: 100%;
  @media only screen and (max-width: 767px) and (min-width: 500px) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
`;

export const PaginationContainer = styled(Grid.Row)`
  @media only screen and (max-height: 999px) and (min-height: 931px) {
    padding-top: 2rem !important;
    padding-bottom: 0 !important;
  }
  @media only screen and (max-height: 1179px) and (min-height: 1000px) {
    padding-top: 3rem !important;
    padding-bottom: 0 !important;
  }
  @media only screen and (max-height: 2000px) and (min-height: 1180px) {
    padding-top: 4rem !important;
    padding-bottom: 0 !important;
  }
`;

export const MobileCalendarContainer = styled(Accordion.Content)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

export const OverviewFilterSegmentContainer = styled(Grid.Column)`
  padding-top: 1rem !important;
`;

export const SegmentMobileArrow = styled(Icon)`
  align-self: center;
  margin-right: -0.5rem !important;
  color: #7b7b7b;
`;

export const StyledCalendar = styled(Calendar)`
  border: none;
  font-family: inherit;
  &&& .react-calendar__navigation__label {
    font-family: inherit;
  }
  &&& .react-calendar__navigation button,
  .react-calendar__month-view__weekdays__weekday {
    color: #003a7d;
  }
  &&& .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background: #003a7d10;
    border-radius: 2rem;
  }
  &&& .react-calendar__month-view__days__day--weekend {
    color: #f49d07;
  }
  &&& .react-calendar__tile--now {
    background: #f49d07;
    border-radius: 2rem;
  }
  &&& .react-calendar__tile--active {
    background: #003a7d;
    border-radius: 2rem;
  }
  &&& .react-calendar__tile:enabled:hover {
    background-color: #003a7d10;
    color: #003a7d;
    border-radius: 2rem;
  }
  &&& .highlight:after {
    content: '*';
    color: #ff0000ff;
    font-size: 1.3rem;
  }
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    max-width: fit-content;
    margin: auto;

    &&& .react-calendar__tile--active {
      background: #003a7d !important;
      border-radius: 2rem;
      color: #ffffff !important;
    }
  }
`;
export const ListSpan = styled.span`
  padding: 0 1rem;
  font-weight: bold;
`;
export const ListContent = styled(List.Content)`
  cursor: pointer;
`;
