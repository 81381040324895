import Logger from '@common/Logger.js';
import { getBaseUrl, getBaseHeaders } from '@services/baseService';
import baseService from '@services/baseService.js';

export const getAxiosConfiguration = (path) => {
  const urlPart = path ? getBaseUrl() + path : getBaseUrl();

  return {
    url: urlPart,
    method: 'GET',
    headers: getBaseHeaders()
  };
};

export const getService = async (path, callBackError = null) => {
  try {
    return await baseService(getAxiosConfiguration(path));
  } catch (error) {
    Logger.error(error);
    if (callBackError) {
      callBackError();
    }
  }
};

export const getServiceByConfig = async (config, callBackError = null) => {
  try {
    return await baseService(config);
  } catch (error) {
    Logger.error(error);
    if (callBackError) {
      callBackError(error);
    }
  }
};

export const getServiceWithCallback = async (config, callbackSuccess, callbackError) => {
  try {
    if (!config) {
      return Promise.resolve(null);
    }
    return new Promise((resolve, reject) => {
      baseService(config)
        .then((response) => {
          if (callbackSuccess) {
            callbackSuccess(response);
          }
          resolve(response);
        })
        .catch(function (error) {
          let errorResponse = error;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            errorResponse = error.response;
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            errorResponse = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorResponse = error.message;
          }
          if (callbackError) {
            callbackError(errorResponse);
          }
          reject(error);
        });
    });
  } catch (error) {
    Logger.error(error);
  }
};

export const postAxiosConfiguration = (path, data) => {
  const urlPart = path ? getBaseUrl() + path : getBaseUrl();

  return {
    url: urlPart,
    method: 'POST',
    headers: getBaseHeaders(),
    data: data
  };
};

export const postService = async (path, data) => {
  try {
    return await baseService(postAxiosConfiguration(path, data));
  } catch (error) {
    Logger.error(error);
  }
};

export const patchAxiosConfiguration = (path, data) => {
  const urlPart = path ? getBaseUrl() + path : getBaseUrl();

  return {
    url: urlPart,
    method: 'PATCH',
    headers: getBaseHeaders(),
    data: data
  };
};

export const patchService = async (path, data, callBackError = null) => {
  try {
    return await baseService(patchAxiosConfiguration(path, data));
  } catch (error) {
    Logger.error(error);
    if (callBackError) {
      callBackError(error);
    }
  }
};
