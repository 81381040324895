import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';
import styled, { css } from 'styled-components';

import forwardAsProp from './forwardAsProp';

const noTopBottomPatting = css`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const MainCatalogGrid = styled(Grid)`
  margin: 0.5em auto !important;
`;

export const MainEventGrid = styled(Grid)`
  margin: 0.5em auto !important;
`;

export const CardHeaderFlagColumn = styled(Grid.Column)`
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    float: left;
  }

  @media only screen and (max-width: 1350px) and (min-width: 992px) {
    float: right !important;
    text-align: end;
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 1300px) and (min-width: 991px) {
    padding-left: 0.5rem !important;
  }

  @media only screen and (max-width: 280px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    float: left;
  }
`;

export const ButtonsColumn = styled(Grid.Column)`
  @media only screen and (max-width: 767px) and (min-width: 320px) {
    padding-right: inherit !important;
    margin-left: -1rem !important;
  }
`;

export const StyledRoundButtons = styled(Button)`
  border-radius: 1rem !important;
  ${(props) => props.customstyle ?? {}}
`;

export const StyledRow = styled(Grid.Row)`
  ${(props) => props.customstyle ?? {}}
`;

export const StyledColumn = styled(Grid.Column)`
  ${(props) => props.customstyle ?? {}}
`;

export const StyledInput = styled.input`
  ${(props) => props.customstyle ?? {}}
`;

export const StyledInputContainer = styled.div`
  ${(props) => props.customstyle ?? {}}
`;

export const CatalogGridColumnContainer = styled(Grid.Column)`
  padding: 0 !important;
`;

export const CatalogListContainer = styled(MainCatalogGrid)`
  @media only screen and (min-width: 990px) {
    min-height: 80vh;
  }
  @media only screen and (max-width: 990px) and (min-width: 767px) {
    min-height: calc(100vh - 17rem);
  }
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    min-height: calc(100vh - 12rem);
  }
`;

export const CatalogContentColumnContainer = styled(Grid.Column)`
  padding: 0 !important;
`;

export const CatalogSearchRowContainer = styled(Grid.Row)`
  padding-bottom: 1rem !important;
`;

export const FiltersMobileRowContainer = styled(Grid.Row)`
  padding-bottom: 1rem !important;
  text-align: center;
`;

export const SortersMobileRowContainer = styled(Grid.Row)`
  padding-bottom: 1rem !important;
`;

export const FlexRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterButtonsContainer = styled.div`
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  margin-right: 0.5rem;
  column-gap: 1.5rem;
  @media only screen and (max-width: 766px) and (min-width: 280px) {
    padding-left: 0.8rem;
    padding-right: 0.4rem;
    margin-right: 0;
    margin-top: 1rem;
  }
  @media only screen and (max-width: 991px) and (min-width: 766px) {
    margin-top: 1rem;
  }
`;

export const StyledFilterButtons = styled(Button)`
  background: #003a7d !important;
  color: white !important;

  @media only screen and (max-width: 991px) {
    margin-top: 2rem !important;
  }
`;

export const CatalogPaginationContainer = styled(MainCatalogGrid)`
  @media only screen and (max-width: 766px) and (min-width: 280px) {
    padding-top: 2rem !important;
    margin-left: 0 !important;
  }
  @media only screen and (max-width: 1919px) and (min-width: 991px) {
    padding-bottom: 1rem !important;
  }
`;

export const StyledSidebar = forwardAsProp(styled(Sidebar)`
  padding-right: 1rem;
  padding-bottom: 2rem;
  background: white;
  overflow-x: hidden;
  top: 91px !important;
  height: 78% !important;
  z-index: 0 !important;
`);

export const CatalogColumn = styled(Grid.Column)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const FilterSegment = styled(Segment)`
  background: #f9f9f9 !important;
`;

export const FilterContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 0.7rem;
  margin-top: 1rem;
`;

export const FilterHeader = styled(Header)`
  color: #003a7d !important;
  margin-bottom: 1rem !important;
`;

export const FilterContainerCloseIcon = styled(Icon)`
  color: gray;
  cursor: pointer;
`;

export const CatalogContentRowContainer = styled(Grid.Row)`
  @media only screen and (max-width: 2560px) and (min-width: 991px) {
    ${noTopBottomPatting}
  }
`;

export const CatalogContentListRowContainer = styled(CatalogContentRowContainer)`
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    min-height: 71vh;
  }
  @media only screen and (max-width: 1400px) and (min-width: 992px) {
    min-height: 67vh;
  }
  @media only screen and (max-width: 1599px) and (min-width: 1401px) {
    min-height: 68vh;
  }
  @media only screen and (max-width: 1919px) and (min-width: 1600px) {
    min-height: 71vh;
  }
  @media only screen and (max-width: 2560px) and (min-width: 1920px) {
    min-height: 71vh;
  }
  @media only screen and (max-height: 1300px) and (min-height: 1025px) {
    min-height: 76vh;
  }
  @media only screen and (min-height: 1301px) {
    min-height: 79vh;
  }
`;

export const FilterSortIconsColumnContainer = styled(Grid.Column)`
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`;

export const FilterSortIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  cursor: pointer;
  padding-top: 1.08rem;
`;

export const CatalogSearchColumnContainer = styled(Grid.Column)`
  @media only screen and (max-width: 1919px) and (min-width: 991px) {
    padding-right: 0 !important;
  }
`;
