import React, { Fragment, useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import { Grid, Input, Icon } from 'semantic-ui-react';

import { courseType } from '@common/Constants';
import Logger from '@common/Logger.js';
import useFetching from '@hooks/Fetching/Fetching';

import BookingInformation from './BookingInformation';
import EmployeesDialog from './EmployeesDialog';
import { bookingRole, bookingType } from '../../Constants';
import { createManagerEmployeesWithCoursesService } from '../MainHelper';
import style from '../style/RegistrationCourse.module.scss';

const ParticipantInformationManager = ({
  participantDetails,
  setParticipantDetails,
  courseTypeName,
  config,
  finishedPrerequisites,
  checkBookingStatusForTeamMembers
}) => {
  const [allEmployees, setAllEmployees] = useState([]);
  const [showEmployeesDialog, setShowEmployeesDialog] = useState(false);

  const [employeesCoursesData, isLoading, setIsLoading, fetchEmployeesCourses] = useFetching(
    createManagerEmployeesWithCoursesService.bind(
      null,
      config?.user ? config.user : 'tobias.rothley@contrimo.com' // TODO: remove temporary email when login is in function
    ),
    true
  );

  const addParticipant = () => {
    const dates = [];
    const hotelsRequired = [];
    for (let i = 0; i < participantDetails.iltModulesSessions.length; i++) {
      hotelsRequired.push(false);
      dates.push({ arrivalDate: '', departureDate: '' });
    }

    const newParticipant = {
      bookingRole: [bookingRole.learner],
      customerNo: '',
      name: '',
      contact: '',
      hotelsRequired: hotelsRequired,
      dates: dates,
      iltModulesSessions: participantDetails.iltModulesSessions,
      participantInfo: undefined
    };

    setParticipantDetails({
      ...participantDetails,
      participants: [...participantDetails.participants, newParticipant]
    });
  };

  const deleteParticipant = (participantIndex) => {
    setParticipantDetails((prevState) => {
      const updatedParticipants = prevState.participants.filter(
        (_, index) => index !== participantIndex
      );

      return {
        ...prevState,
        participants: updatedParticipants
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        fetchEmployeesCourses();
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          employeesCoursesData?.data?.employeesWithCourses &&
          employeesCoursesData?.data?.employeesWithCoursesCount
        ) {
          const employeesWithCourses = employeesCoursesData.data.employeesWithCourses.reduce(
            (acc, employee) => {
              const existingEmployee = acc.find((item) => item.employeeId === employee.employeeId);

              const courseData = {
                id: employee.course.id,
                name: employee.course.name,
                complete: employee.course.complete
              };

              if (employee.course.iltSessionsDtos.length === 0) {
                if (existingEmployee) {
                  existingEmployee.eLearnings.push(courseData);
                } else {
                  acc.push({
                    employeeId: employee.employeeId,
                    managerId: employee.managerId,
                    managerName: employee.managerName,
                    userName: employee.userName,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    active: employee.active,
                    email: employee.email,
                    phoneWork: employee.phoneWork,
                    phoneMobile: employee.phoneMobile,
                    numberOfCourses: employee.numberOfCourses,
                    numberOfCertificates: employee.numberOfCertificates,
                    eventParticipantDto: employee.eventParticipantDto,
                    courses: [],
                    eLearnings: [courseData]
                  });
                }
              } else {
                if (existingEmployee) {
                  existingEmployee.courses.push(courseData);
                } else {
                  acc.push({
                    employeeId: employee.employeeId,
                    managerId: employee.managerId,
                    managerName: employee.managerName,
                    userName: employee.userName,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    active: employee.active,
                    email: employee.email,
                    phoneWork: employee.phoneWork,
                    phoneMobile: employee.phoneMobile,
                    numberOfCourses: employee.numberOfCourses,
                    numberOfCertificates: employee.numberOfCertificates,
                    eventParticipantDto: employee.eventParticipantDto,
                    courses: [courseData],
                    eLearnings: []
                  });
                }
              }

              return acc;
            },
            []
          );

          setAllEmployees(employeesWithCourses);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [employeesCoursesData]);

  const checkIfLastEmployeeIsFilled = () => {
    return participantDetails.participants.length
      ? participantDetails.participants[participantDetails.participants.length - 1]
          .participantInfo === undefined
      : false;
  };

  return (
    <Grid
      data-testid={'ParticipantInformationManager'}
      className={`tablet computer only ${style.menuCenter}`}
      centered
      style={{ background: config?.configuration?.CourseDetailsContent.background }}
    >
      <Grid.Row columns={1}>
        <Grid.Column textAlign={'left'}>
          <b>{Localize.get('RegistrationForm.bookMyTeam')}</b>
        </Grid.Column>
      </Grid.Row>
      {participantDetails?.participants &&
        participantDetails.participants.map((participant, participantIndex) => (
          <Grid.Row key={participantIndex}>
            <Grid.Column width={4}>
              <Grid.Row>
                <Icon
                  name='trash'
                  size='large'
                  onClick={() => deleteParticipant(participantIndex)}
                  className={style.registrationAddRemoveButtonColor}
                />
                <div
                  onClick={() => {
                    if (participant.participantInfo === undefined) setShowEmployeesDialog(true);
                  }}
                  onKeyUp={() => {}}
                  role='button'
                  tabIndex={0}
                  style={{ display: 'inline-block', cursor: 'pointer' }}
                >
                  <Input
                    disabled
                    value={participant.name}
                    input={{
                      style: {
                        backgroundColor:
                          participant.participantInfo !== undefined
                            ? config?.configuration?.Registration.registrationDisabledColor
                            : '',
                        background:
                          participant.participantInfo !== undefined
                            ? config?.configuration?.Registration.registrationDisabledColor
                            : '',
                        fontWeight:
                          participant.participantInfo !== undefined
                            ? config?.configuration?.Registration.participantNameWeight
                            : ''
                      }
                    }}
                  />
                </div>
                <EmployeesDialog
                  key={participantIndex}
                  showEmployeesDialog={showEmployeesDialog}
                  setShowEmployeesDialog={setShowEmployeesDialog}
                  loadingEmployees={isLoading}
                  allEmployees={allEmployees}
                  setParticipantDetails={setParticipantDetails}
                  participantIndex={participantIndex}
                />
              </Grid.Row>
              <Grid.Row>
                {participant.participantInfo !== undefined &&
                  !finishedPrerequisites(
                    participant.participantInfo.courses,
                    participant.participantInfo.eLearnings
                  ) && (
                    <Fragment>
                      <Icon
                        name='exclamation triangle'
                        size='big'
                        className={style.registrationPrerequisitesWarning}
                      />
                      {Localize.get('RegistrationForm.participantDoesNotHavePrerequisites')}
                    </Fragment>
                  )}
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={12}>
              <Grid>
                {participant.iltModulesSessions.length &&
                courseTypeName === Localize.get(`App.${courseType.course}`) ? (
                  participant.iltModulesSessions.map((iltModule, index) => (
                    <BookingInformation
                      key={index}
                      participantDetails={participant}
                      setParticipantDetails={setParticipantDetails}
                      iltModule={iltModule}
                      iltModuleIndex={index}
                      config={config}
                      participantIndex={participantIndex}
                      bookingInformationType={bookingType.forMyTeam}
                      bookingInformationStatus={checkBookingStatusForTeamMembers(
                        iltModule,
                        participantIndex
                      )}
                    />
                  ))
                ) : (
                  <Fragment />
                )}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        ))}

      <Grid.Row>
        <Grid.Column
          computer={16}
          tablet={10}
          style={{ borderTop: config?.configuration.Registration.border }}
        ></Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign='center'>
          <Grid>
            <Grid.Column width={3}>
              <Icon
                name='plus circle'
                size='huge'
                onClick={addParticipant}
                className={style.registrationAddRemoveButtonColor}
                disabled={checkIfLastEmployeeIsFilled()}
              />
              <Grid.Row>{Localize.get('RegistrationForm.addTeamMember')}</Grid.Row>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ParticipantInformationManager;
