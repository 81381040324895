import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

// semantic
import get from 'lodash/get';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import Logger from '@common/Logger.js';
import { isJsonString } from '@common/Util';

import style from '../EventDetails/style/EventDetails.module.scss';

const SessionDetailsNote = (props) => {
  const { notesData, isLoading, noteType } = props;

  const [sessionNote, setSessionNote] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      try {
        if (notesData?.length) {
          setSessionNote(notesData.find((note) => note.type.key === noteType.key));
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesData]);

  const getNoteText = (text) => {
    if (isJsonString(text)) {
      return get(JSON.parse(text), 'blocks[0].text');
    } else {
      return text;
    }
  };

  return (
    <>
      {isLoading && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )}
      <Segment secondary className={style.contentHeight} data-testid={'sessionDetailNote'}>
        <Header as='h4' className={style.headerMargin}>
          {Localize.get('ILTSession.agendaNote')}
        </Header>

        {sessionNote ? (
          <div data-testid={'iltSessionNoteContent'} className={style.notesPlaceholder}>
            {getNoteText(sessionNote?.text)}
          </div>
        ) : (
          <div data-testid={'noILTSessionNoteContainer'}>
            {Localize.get('ILTSession.noNoteForNoteType', { noteType: noteType.name })}
          </div>
        )}
      </Segment>
    </>
  );
};

export default SessionDetailsNote;
